import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

const AllWineners = () => {
    const [WinnersDialog, setWinnersDialog] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    
    const [winners, setWinners] = useState(null);
    const getWinners = async () => {
        const result = await fetch("https://chessplus.in/api/get_all_winners");
        const response = await result.json();
        if (response.status === 200) {
            setWinners(response.result);
        } else {
            alert(response.message);
        }
    };

    useEffect(() => {
        getWinners();

    }, []);

    const openNew = () => {
        setWinnersDialog(true);
    };

    const hideDialog = () => {
        setWinnersDialog(false);
    };


    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button
                    label="Add"
                    icon="pi pi-plus"
                    severity="success"
                    onClick={openNew}
                />
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <Button
                label="Export"
                icon="pi pi-upload"
                className="p-button-help"
                onClick={exportCSV}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-flex announcementButton">
                <Button
                    icon="pi pi-trash"
                    rounded
                    outlined
                    severity="danger"
                    onClick={() => { }}
                />
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">All Winners</h4>
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                />
            </IconField>
        </div>
    );
    const WinnersDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
        </React.Fragment>
    );

    return (
        <div className="AllWinners">
            <Toast ref={toast} />
            <div className="card">
                <DataTable
                    ref={dt}
                    value={winners}
                    selection={selectedProducts}
                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Data"
                    globalFilter={globalFilter}
                    header={header}
                >
                    <Column field="createdAt" header="Date" sortable></Column>
                    <Column field="winnerName" header="winnerName" sortable></Column>
                    <Column field="winnerMobile" header="winnerMobile" sortable></Column>
                    <Column field="color" header="color" sortable></Column>
                    <Column field="roomid" header="roomid" sortable></Column>
                    <Column field="status" header="status" bodyStyle={{ color: 'green', fontWeight:"bold" }} sortable></Column>
                    <Column body={actionBodyTemplate} exportable={false}></Column>

                </DataTable>
            </div>

            <Dialog
                visible={WinnersDialog}
                style={{ width: "32rem" }}
                breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                header="winners Add"
                modal
                className="p-fluid"
                footer={WinnersDialogFooter}
                onHide={hideDialog}
            >

            </Dialog>

        </div>
    );
};

export default AllWineners