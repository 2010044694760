import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { socket, url } from "../helpers/apiHelpers";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../redux/action";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const _id = localStorage.getItem("userId");

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await axios.post(`${url}/api/get-user`, {
          _id,
        });

        const data = response.data;

        dispatch(addUser(data.user));

        if (data.user?.isInGame) {
          socket.emit("reconnection", { roomId: data.user.activeGameRoomId });
          // navigate(`/room/${data.user.activeGameRoomId}`);
        }
      } catch (e) {
        console.log("Error while fetching user details", e.message);
      }
    };
    getUserDetails();
  }, [dispatch, _id]);

  const joinChessRoom = (min) => {
    socket.emit("join_room", {
      username: user?.username,
      min: min,
    });

    navigate(`/waiting`);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-3 col-md-6 col-12">
          <div className="card px-3 py-5 bg-info text-center h-100">
            <a href={"https://chessplus.in/withcomputer/index.html"}>
              <h4 className="text-white"><i class="fa fa-solid fa-computer"></i> Play with Computer</h4>
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          <div className="card px-3 py-5 bg-primary text-center h-100">
            <Link to={"/users/tournaments"}>
              <h4 className="text-white"> <i className="fa fa-chess"></i> All Tournaments</h4>
            </Link>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          <div className="card px-3 py-5 bg-warning text-center h-100">
            <Link to={"/users/join-tournaments"}>
              <h4 className="text-dark"> <i className="fa fa-chess"></i> Join Tournaments</h4>
            </Link>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          <div className="card px-3 py-5 bg-danger text-center h-100">
            <Link to={"/users/winning-games"}>
              <h4 className="text-white"> <i className="fa fa-trophy"></i> Winning Games</h4>
            </Link>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          <div className="card px-3 py-5 bg-info text-center h-100">
            <Link to={"/users/profile"}>
              <h4 className="text-white"> <i className="fa fa-user"></i> Profile</h4>
            </Link>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          <div className="card px-3 py-5 bg-primary text-center h-100">
            <h4 className="text-white">Completed Matches</h4>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          <div className="card px-3 py-5 bg-success text-center h-100">
            <h4 className="text-white">Live Matches</h4>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          <div className="card px-3 py-5 bg-danger text-center h-100">
            <h4 className="text-white">Upcomming Matches</h4>
          </div>
        </div>
      </div>

      {/*</>// <div style={rootDiv}>
    //   <div
    //     style={{
    //       height: 60,
    //       display: "flex",
    //       width: "100%",
    //       backgroundColor: "#eabf69",
    //       alignItems: "center",
    //       justifyContent: "space-around",
    //     }}
    //   >
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <h3 style={{ marginLeft: 5 }}>
    //         <i className="fa fa-user"> </i> {user?.username}
    //       </h3>
    //     </div>

    //     <img
    //       src={"./logo.png"}
    //       alt=""
    //       className="brand_logo"
    //     />

    //     <button onClick={logout} style={{ fontWeight: "bold" }}>
    //       {" "}
    //       Logout
    //     </button>
    //   </div>

    //   <div style={{ padding: 10 }}>
    //     <h2 style={{ color: "white" }}> Play </h2>
    //     <div
    //       style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
    //     >
    //       <button
    //         onClick={() => {
    //           joinChessRoom(3);
    //         }}
    //         style={timeComp}
    //       >
    //         3 min
    //       </button>
    //       <button
    //         onClick={() => {
    //           joinChessRoom(5);
    //         }}
    //         style={timeComp}
    //       >
    //         5 min
    //       </button>
    //       <button
    //         onClick={() => {
    //           joinChessRoom(10);
    //         }}
    //         style={timeComp}
    //       >
    //         10 min
    //       </button>
    //       <button
    //         onClick={() => {
    //           joinChessRoom(15);
    //         }}
    //         style={timeComp}
    //       >
    //         15 min
    //       </button>
    //       <button
    //         onClick={() => {
    //           joinChessRoom(30);
    //         }}
    //         style={timeComp}
    //       >
    //         30 min
    //       </button>
    //     </div>
    //     <br />

    //     <h2 style={{ color: "white" }}> Other </h2>

    //     <div
    //       style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
    //     >
    //       <button style={otherComponent}>Custom Game</button>
    //       <button style={otherComponent}>Play Friend</button>
    //     </div>
    //   </div>
    // </div> */}
    </>
  );
};

const rootDiv = {
  display: "flex",
  height: "100vh",
  backgroundColor: "rgb(46, 46, 46)",
  flexDirection: "column",
};

const timeComp = {
  color: "white",
  backgroundColor: "rgba(191, 41, 41, 0.5)",
  height: 60,
  margin: 2,
  fontSize: 20,
};

const otherComponent = {
  color: "white",
  backgroundColor: "rgba(191, 41, 41, 0.5)",
  height: 60,
  margin: 2,
  fontSize: 16,
};

export default Home;
