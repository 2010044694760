import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addUser } from "../redux/action";
import { useDispatch } from "react-redux";
import Captcha from "./Captcha";
import { url } from "../helpers/apiHelpers";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(null);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCaptchaValidation = (isValid) => {
    setIsCaptchaValid(isValid);
  };
  const getLogin = async () => {
    if (!username || !password) {
      alert("Fill all Mandatory Fields");
      return;
    } else {
      const result = await fetch("https://chessplus.in/api/login_now", {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      let res = await result.json();
      if (res.status === 200) {
        localStorage.setItem("userId", res.user._id);
        localStorage.setItem("userType", "User");
        navigate("/users/home");
        dispatch(addUser(res.user));
      } else {
        setIsCaptchaValid(null)
        alert(res.message);
      }
    }
  };
  // LoginRegister
  return (
    <div
      className="w-100 admin-Login-bg "
      style={{
        backgroundImage: `url(${url}/img/bg-image.jpg)`,
        backgroundSize: "cover",
        height: "100vh !important",
      }}
    >
      <div className="container px-0 p-3">
        <div className="position-relative">
          <div className="row m-lg-5 m-3 no-gutters shadow-lg">
            <div className="col-md-6 px-0 ">
              <img
                src={`${url}/img/chess2.jpg`}
                className="img-fluid"
                style={{ minHeight: "100%" }}
              />
            </div>
            <div className="col-md-6 bg-white p-lg-5 py-5 px-3">
              <h3 className="pb-3">User Login</h3>
              <div className="form-style">
                <div className="form-group pb-3">
                  <input
                    type="text"
                    placeholder="Email / Username"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="form-group pb-3 position-relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    className="form-control"
                    id="exampleInputPassword1"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Link onClick={togglePasswordVisibility} className="adminEyeIcon">
                    {showPassword ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
                  </Link>
                </div>
                <div className="form-group mt-4">
                  
                  <Captcha onValidate={handleCaptchaValidation} isCaptchaValid={isCaptchaValid} />
                  {isCaptchaValid !== null && (
                    <p >
                      {isCaptchaValid
                        ? <span className="text-success">CAPTCHA validated successfully!</span>
                        : <span className="text-danger">CAPTCHA validation failed. Please try again. </span>}
                    </p>
                  )}
                </div>
                <div className="pb-3 mt-2">
                  <button
                    type="button"
                    className="btn btn-dark w-100 font-weight-bold mt-2"
                    onClick={() => getLogin()}
                  >
                    Login
                  </button>
                </div>
                <div className="">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-0 text-dark d-flex align-items-center justify-content-between">
                      If you don't have an account?
                    </p>
                    <Link to={"/register"} className="text-info">
                      Create New Account
                    </Link>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <Link to={"/forgot-passwords"} className="text-info">
                      Forgot Password
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  return (
    <div className=" py-5 ">
      <div className="container pb-5">
        <div className="row">
          <div className="col-lg-5 col-md-6 col-12 m-auto">
            <div className="card py-3 px-4 text-center bg-light">
              <div className="col-lg-12 login-key">
                <i className="fa fa-key" aria-hidden="true"></i>
              </div>
              <div className="col-lg-12 login-title">User Login</div>
              <div className="login-form">
                <div className="form-group mt-3">
                  <label className="form-control-label fw-bold">
                    Email / Username
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setUsername(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group mt-3 position-relative">
                  <label className="form-control-label fw-bold">Password</label>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                  />
                  <Link onClick={togglePasswordVisibility} className="eyeIcon">
                    {showPassword ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
                  </Link>
                </div>
                <div className="form-group mt-3">
                  <label className="form-control-label fw-bold">CAPTCHA</label>
                  <Captcha onValidate={handleCaptchaValidation} isCaptchaValid={isCaptchaValid} />
                  {isCaptchaValid !== null && (
                    <p >
                      {isCaptchaValid
                        ? <span className="text-success">CAPTCHA validated successfully!</span>
                        : <span className="text-danger">CAPTCHA validation failed. Please try again. </span>}
                    </p>
                  )}
                </div>

                <div className="row ">
                  <div className="col-lg-12 mb-4 login-btm login-button text-center">
                    <button
                      type="button"
                      onClick={() => getLogin()}
                      className="btn btn-outline-primary"
                      disabled={isCaptchaValid && password ? false : true}
                    >
                      LOGIN
                    </button>
                  </div>
                </div>
                <div className="row  mt-1">
                  <div className="col-lg-12 login-btm login-button text-center">
                    <p className="text-white">
                      {" "}
                      If you don't have an account?
                      <Link to={"/register"} className="text-info">
                        {""} Create New Account
                      </Link>
                    </p>
                    <p className="text-white">
                      <Link to={"/forgot-passwords"} className="text-info">
                        Forgot Password
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
