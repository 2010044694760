import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import Announcement from "./Announcement";
import { url } from "../../helpers/apiHelpers";

const AllTournamentsAdmin = () => {
    const [tournament, setTournament] = useState(null);
    const [TournamentDialog, setTournamentDialog] = useState(false);
    const [deleteTournamentDialog, setDeleteTournamentDialog] = useState(false);
    const [viewAllTeams, setViewAllTeams] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [Title, setTitle] = useState(null);
    const [Description, setDescription] = useState(null);
    const [TimeControl, setTimeControl] = useState(null);
    const [Players, setPlayers] = useState(null);
    const [MovingTime, setMovingTime] = useState(null);
    const [StartDate, setStartDate] = useState(null);
    const [EndDate, setEndDate] = useState(null);
    const [TournamentStartDate, setTournamentStartDate] = useState(null);
    const [TournamentEndDate, setTournamentEndDate] = useState(null);
    const [rounds, setRounds] = useState(null);
    const [image, setImage] = useState(null);
    const [selectedTournaments, setSelectedTournaments] = useState("");

    const getTournaments = async () => {
        const result = await fetch(`${url}/api/get_tournament`);
        const response = await result.json();
        console.log(response);
        if (response.status === 200) {
            setTournament(response.result);
        } else {
            alert(response.message);
        }
    };

    const [packageId, setPackageId] = useState("");
    const [packages, setPackages] = useState(null);
    const getPackages = async () => {
        const result = await fetch(`${url}/api/get_packages`);
        const response = await result.json();
        if (response.status === 200) {
            setPackages(response.result);
        } else {
            alert(response.message);
        }
    };

    useEffect(() => {
        getPackages();
        getTournaments();
    }, []);

    const openNew = () => {
        setTournamentDialog(true);
    };

    const hideDialog = () => {
        setTournamentDialog(false);
        setViewAllTeams(false);
    };

    const hideDeleteTournamentDialog = () => {
        setDeleteTournamentDialog(false);
    };

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < tournament.length; i++) {
            if (tournament[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const [SingleTournament, setSingleTournament] = useState([]);
    const [allTeams, setAllTeams] = useState([]);
    const getTeams = async (rowData) => {
        setViewAllTeams(true)
        setSingleTournament(rowData)
        const result = await fetch(`${url}/api/get_all_team`, {
            method: "POST",
            body: JSON.stringify({ tournamentId: rowData._id }),
            headers: {
                "Content-Type": "application/json"
            }
        });
        const response = await result.json();
        if (response.status === 200) {
            setAllTeams(response.result);
        } else {
            alert(response.message);
        }
    }

    const hideAllTeamsFooter = (
        <React.Fragment>
            <Button
                label="No"
                icon="pi pi-times"
                outlined
                onClick={hideDialog}
            />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={{}} style={{ marginLeft: 5 }} />
        </React.Fragment>
    );
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-flex announcementButton">
                {/* <Button
                    icon="pi pi-trash"
                    rounded
                    outlined
                    severity="danger"
                    onClick={() => { }}
                /> */}
                <Button
                    label="View"
                    rounded
                    outlined
                    severity="primary"
                    onClick={() => getTeams(rowData)}
                    style={{ marginLeft: 5 }}
                />
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">All Tournaments</h4>
            <Button
                label="Add"
                icon="pi pi-plus"
                severity="success"
                onClick={openNew}
            />
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                />
            </IconField>
        </div>
    );
    const TournamentDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button
                label="Save"
                icon="pi pi-check"
                onClick={() => saveTournaments()}
            />
        </React.Fragment>
    );

    const saveTournaments = async () => {
        if (!rounds || !Players || !StartDate || !EndDate || !packageId) {
            alert("Please fill the mandatory fields");
            return
        } else {
            let packageTitle = "";
            let packageAmount = "";
            packages.map(item => {
                if (item._id === packageId) {
                    packageTitle = item.packageTitle
                    packageAmount = item.packageAmount
                }
            })

            const formData = new FormData();
            formData.append("Title", Title);
            formData.append("Description", Description);
            formData.append("packageId", parseInt(packageId))
            formData.append("packageTitle", packageTitle)
            formData.append("packageAmount", packageAmount)
            formData.append("TimeControl", TimeControl);
            formData.append("Players", Players);
            formData.append("MovingTime", MovingTime);
            formData.append("StartDate", StartDate);
            formData.append("EndDate", EndDate);
            formData.append("TournamentStartDate", TournamentStartDate);
            formData.append("TournamentEndDate", TournamentEndDate);
            formData.append("rounds", rounds);
            formData.append("image", image);

            const result = await fetch(`${url}/api/add_tournament`, {
                method: "POST",
                body: formData,
            });
            const response = await result.json();
            console.log(response);
            if (response.status === 200) {
                alert(response.message);
                window.location.reload();
            } else {
                alert(response.message);
            }
        }
    };

    const deleteTournamentDialogFooter = (
        <React.Fragment>
            <Button
                label="No"
                icon="pi pi-times"
                outlined
                onClick={hideDeleteTournamentDialog}
            />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={{}} />
        </React.Fragment>
    );

    const [createRoomDialog, setCreateRoomDialog] = useState(false)

    const [selectedUser, setSelectedUser] = useState([]);

    const getSelected = (rowData) => {
        setCreateRoomDialog(true);
        setSelectedUser(rowData)
    }

    const createRoomButton = (rowData) => {
        if (rowData.activeGameRoomId === undefined && rowData.isInGame === undefined) {
            return <Button label="Create Room" severity="primary" onClick={() => getSelected(rowData)} />
        } else {
            return <Button label="Room Joined" severity="primary" />
        }
    }

    const hideCreateRoomDialog = () => {
        setCreateRoomDialog(false);
    }

    const getOpponentUser = async (uId) => {
        if (!uId) {
            alert("Select Opponent First");
            return
        } else {
            // console.log(SingleTournament._id);
            let opponentName = "";
            allTeams.map((items) => {
                if (uId === items.userId) {
                    opponentName = items.username
                }
            })
            const result = await fetch(`${url}/api/update_users`, {
                method: "POST",
                body: JSON.stringify({ tournamentId: SingleTournament._id, userId: selectedUser.userId, userName: selectedUser.username, opponentId: uId, opponentName: opponentName }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const res = await result.json();
            if (res.status === 200) {
                alert(res.message);
                window.location.reload();
            } else {
                alert(res.message);
            }
        }
    }

    const [roundsData, setRoundsData] = useState([])
    const getRounds = async () => {
        const result = await fetch(`${url}/api/get_rounds`)
        const res = await result.json();
        if (res.status === 200) {
            setRoundsData(res.result)
        } else {
            setRoundsData(res.result);
        }
    }
    useEffect(() => {
        getRounds();
    }, []);

    const showRounds = (items) => {
        let buttons = [];
        for (let i = 0; i < items.round; i++) {
            buttons.push(< Link to={`/admin/set-rounds-matches/${items._id}/${i + 1}`} className="btn btn-primary btn-lg me-2 w-20" > Round {i + 1}</Link >)
        }
        return buttons
    }
    const [showTeams, setShowTeams] = useState(false)
    const showAllTeams = () => {
        if (showTeams === false) {
            setShowTeams(true);
        } else {
            setShowTeams(false);
        }
    }

    return (
        <div className="AllTournament">
            <Toast ref={toast} />
            <div className="card">
                <DataTable
                    ref={dt}
                    value={tournament}
                    selection={selectedProducts}
                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Data"
                    globalFilter={globalFilter}
                    header={header}
                >
                    <Column field="title" header="Title" sortable></Column>
                    <Column field="packageAmount" header="Amount" sortable></Column>
                    <Column field="timeControl" header="TimeControl" sortable></Column>
                    <Column field="players" header="Plyrs" sortable></Column>
                    <Column field="round" header="Rounds" sortable></Column>
                    <Column field="joiningStartDate" header="StartDate" sortable></Column>
                    <Column field="joiningEndDate" header="EndDate" sortable></Column>
                    <Column body={actionBodyTemplate} exportable={false}></Column>
                </DataTable>
            </div>

            <Dialog
                visible={TournamentDialog}
                style={{ width: "40rem" }}
                breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                header="Tournaments Add"
                modal
                className="p-fluid"
                footer={TournamentDialogFooter}
                onHide={hideDialog}
            >
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <label>Select Package<span className="text-danger">*</span></label>
                        <select
                            className="form-select"
                            onChange={(e) => setPackageId(e.target.value)}
                        >
                            <option value={""}>Select One</option>
                            {
                                packages && packages.map(item => (
                                    <option value={item._id}>{item.packageTitle}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-lg-6  col-12">
                        <label>Title<span className="text-danger">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Title"
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-12 mt-3">
                        <label>Description</label>
                        <textarea
                            type="text"
                            className="form-control"
                            placeholder="Description"
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="col-lg-6 mt-3">
                        <label>
                            Time Control <small className="text-danger">(In Minutes)</small><span className="text-danger">*</span>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Time Control"
                            onChange={(e) => setTimeControl(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-6 mt-3">
                        <label>Players<span className="text-danger">*</span></label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Players"
                            onChange={(e) => setPlayers(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-6 mt-3">
                        <label>Max Avg. Time/Move </label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Time/Move (In Second)"
                            onChange={(e) => setMovingTime(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-6 mt-3">
                        <label>Rounds<span className="text-danger">*</span> </label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Matches Rounds"
                            onChange={(e) => setRounds(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-6 mt-3">
                        <label>Joining Start Date<span className="text-danger">*</span></label>
                        <input
                            type="date"
                            className="form-control"
                            placeholder="Joining Start Date"
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-6 mt-3">
                        <label>Joining End Date<span className="text-danger">*</span></label>
                        <input
                            type="date"
                            className="form-control"
                            placeholder="Joining End Date"
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-6 mt-3">
                        <label>Tournament Start Date</label>
                        <input
                            type="date"
                            className="form-control"
                            placeholder="Tournament Start Date"
                            onChange={(e) => setTournamentStartDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-6 mt-3">
                        <label>Tournament End Date</label>
                        <input
                            type="date"
                            className="form-control"
                            placeholder="Tournament End Date"
                            onChange={(e) => setTournamentEndDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-6 mt-3">
                        <label>Image<span className="text-danger">*</span></label>
                        <input
                            type="file"
                            className="form-control"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog
                visible={deleteTournamentDialog}
                style={{ width: "32rem" }}
                breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                header="Confirm"
                modal
                footer={deleteTournamentDialogFooter}
                onHide={hideDeleteTournamentDialog}
            ></Dialog>

            <Dialog
                visible={viewAllTeams}
                style={{ width: "40rem" }}
                breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                header="Manage Teams"
                modal
                // footer={hideAllTeamsFooter}
                onHide={hideDialog}
            >
                <div className="row">
                    <div className="col-lg-8 col-12">
                        <p className="mb-0"><strong className="text-primary">Title :</strong> {SingleTournament.title}</p>
                        <p className="mb-0"><strong className="text-primary">Description :</strong> {SingleTournament.description}</p>
                        <p className="mb-0"><strong className="text-primary">Players :</strong> {SingleTournament.players}</p>
                        <p className="mb-0"><strong className="text-primary">StartDate :</strong> {SingleTournament.joiningStartDate}</p>
                        <p className="mb-0"><strong className="text-primary">EndDate :</strong> {SingleTournament.joiningEndDate}</p>
                        <p className="mb-0"><strong className="text-primary">Total Rounds : {SingleTournament.round}</strong></p>
                    </div>
                    <div className="col-lg-4 col-12">
                        <button className="btn btn-outline-primary" onClick={() => showAllTeams()}>View All Joined Teams</button>
                    </div>
                    <div className="col-lg-12 col-12 mt-3">
                        <div className="text-center">
                            {
                                showRounds(SingleTournament)
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="col-lg-12 col-12 mt-1">
                        <div className={showTeams === true ? "d-block" : "d-none"}>
                            <Announcement tournamentId={SingleTournament._id} />
                        </div>
                    </div>
                </div>
            </Dialog >
            <Dialog
                visible={createRoomDialog}
                style={{ width: "32rem" }}
                breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                header="Confirm"
                modal
                onHide={hideCreateRoomDialog}
            >
                <div className="row">
                    <div className="col-lg-12">
                        <label>Player Name</label>
                        <input type="text" className="form-control" value={selectedUser && selectedUser.username} readOnly />
                    </div>
                    <div className="col-lg-12">
                        <label>Select Opponent</label>
                        <select className="form-control form-select" onChange={(e) => getOpponentUser(e.target.value)} >
                            <option value={""}>Select One</option>
                            {
                                allTeams.map((items) => {
                                    if (selectedUser.userId !== items.userId) {
                                        return <option value={items.userId}>{items.username}</option>
                                    }
                                })
                            }
                        </select>
                    </div>
                </div>
            </Dialog>
        </div >
    );
};

export default AllTournamentsAdmin