import React from 'react'
import { useNavigate } from 'react-router-dom'

const PaymentFailure = () => {
    const navigate = useNavigate()
    return (
        <div className='container py-5'>
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="message-box _success _failed shadow">
                        <i class="fa fa-times-circle" aria-hidden="true"></i>
                        <h2> Your payment failed </h2>
                        <p>  Try again later </p>
                        <button className='btn btn-primary mt-5' onClick={() => navigate('/users/tournaments')}>Go Back</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentFailure