import { io } from "socket.io-client";

export const url = "https://chessplus.in";
// export const socket = io("https://chessplus.in", {
//     path: '/chessgame/socket.io',
// }) 
export const socket = io('wss://chessplus.in', {
    path: '/socket.io',
});

// export const url = "http://localhost:8000";
// export const socket = io("http://localhost:8000")