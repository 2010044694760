import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { url } from '../helpers/apiHelpers';

const ForgotPassword = () => {
    const [mobile, setMobile] = useState("");
    const [verifyOTP, setVerifyOTP] = useState(false)
    const [sessionId, setSessionId] = useState("");
    const [otp, setOTP] = useState("")
    const [validMobile, setValidMobile] = useState(false);
    const [userEnterOTP, setUserEnterOTP] = useState(0);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const [cshowPassword, setCShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleCPasswordVisibility = () => {
        setCShowPassword(!cshowPassword);
    };

    const verifyMobile = async () => {
        const result = await fetch(`${url}/api/check_mobile_no`, {
            method: "POST",
            body: JSON.stringify({ mobile }),
            headers: {
                "Content-Type": "application/json"
            }
        });
        const res = await result.json();

        if (res.status === 201) {
            // var otps = Math.floor(1000 + Math.random() * 9000);
            // let apikey = 'ba73739c-38d4-11e9-8806-0200cd936042';
            // let msg = `Hi ${res.user.name}, Your one time password for phone verification is ${otps}`;
            // const encodedMsg = encodeURIComponent(msg);
            // // const response = await fetch(`https://2factor.in/API/V1/${apikey}/SMS/+91${mobile}/${otps}/${encodedMsg}`);
            // const response = await fetch(`https://2factor.in/API/R1/?module=TRANS_SMS&apikey=${apikey}&to=${mobile}&from=chessp&msg=${encodedMsg}`, {
            //     mode: 'no-cors',
            // });
            // console.log(response);
            // const results = await response.json();
            // console.log(results.Status);
            if (res.result.Status === "Success") {
                setSessionId(res.result.Details)
                setOTP(res.otps);
                setValidMobile(true);
            }
        } else {
            setValidMobile(false);
            alert(res.result);
        }
    }

    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])

    const verifyOTPs = async () => {
        // let apikey = 'ba73739c-38d4-11e9-8806-0200cd936042';

        // const response = await axios.get(
        //     `https://2factor.in/API/V1/${apikey}/SMS/VERIFY/${sessionId}/${userEnterOTP}`
        // );
        // if (response.data.Status === 'Success') {
        if (otp == userEnterOTP) {
            setVerifyOTP(true)
            alert('OTP verified successfully!');
        } else {
            alert('Invalid OTP');
        }
    }

    const submitPassword = async () => {
        const result = await fetch(`${url}/api/forgot-password`, {
            method: "POST",
            body: JSON.stringify({ mobile, password }),
            headers: {
                "Content-Type": "application/json"
            }
        });
        const res = await result.json();

        if (res.status === 201) {
            alert(res.result);
            navigate("/")
        } else {
            alert(res.result);
        }
    }


    return (
        <div className="App py-5 LoginRegister forgetPassword">
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-5 col-md-6 col-12 m-auto row login-box py-5 px-3" >
                        <div className="col-lg-12 text-center mb-4 d-flex justify-content-evenly align-items-center">
                            <i className="fa fa-key" aria-hidden="true"></i>
                            <div className="col-lg-12 login-title text-white">Forgot Password</div>
                        </div>

                        <div className={`row login-form ${validMobile === true ? 'disabled' : ""}`}>
                            <div className="col-lg-9 login-form">
                                <div className="form-group">
                                    <label className="form-control-label">
                                        Enter Mobile No.
                                    </label>
                                    <input
                                        type="text"
                                        onChange={(e) => setMobile(e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3 login-btm login-button text-center">
                                <button
                                    type="button"
                                    onClick={() => verifyMobile()}
                                    className="btn btn-outline-info mt-4 w-100"
                                    disabled={validMobile === true ? true : false}
                                >
                                    Check
                                </button>
                            </div>
                        </div>
                        {
                            validMobile === true ? (
                                <div className={`row ${verifyOTP === true ? "disabled" : ""}`}>
                                    <div className="col-lg-12 login-form">
                                        <div className="form-group">
                                            <label className="form-control-label">
                                                Enter OTP Here
                                            </label>
                                            <input
                                                type="text"
                                                onChange={(e) => setUserEnterOTP(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-4 login-btm login-button text-center">
                                        <button
                                            type="button"
                                            onClick={() => verifyOTPs()}
                                            className="btn btn-outline-info"
                                            disabled={verifyOTP === true ? true : false}
                                        >
                                            Verify
                                        </button>
                                    </div>
                                </div>
                            ) : ""
                        }
                        {verifyOTP === true ?
                            (
                                <div className={`row`}>
                                    <div className="col-lg-12 login-form">
                                        <div className="form-group position-relative">
                                            <label className="form-control-label">
                                                Enter New Password
                                            </label>
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={(e) => setPassword(e.target.value)}
                                                className="form-control"
                                            />
                                            <Link onClick={togglePasswordVisibility} className="eyeIcon">
                                                {showPassword ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 login-form">
                                        <div className="form-group position-relative">
                                            <label className="form-control-label">
                                                Enter Confirm Password
                                            </label>
                                            <input
                                                type={cshowPassword ? 'text' : 'password'}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                className="form-control"
                                            />
                                            <Link onClick={toggleCPasswordVisibility} className="eyeIcon">
                                                {cshowPassword ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
                                            </Link>
                                        </div>
                                        {
                                            password != confirmPassword ? (
                                                <p>Password not Matched!</p>
                                            ) : ""
                                        }
                                    </div>
                                    <div className="col-lg-12 mb-4 login-btm login-button text-center">
                                        <button
                                            type="button"
                                            onClick={() => submitPassword()}
                                            className="btn btn-outline-info"
                                            disabled={password === confirmPassword && verifyOTP === true ? false : true}
                                        >
                                            Verify
                                        </button>
                                    </div>
                                </div>
                            ) : ""}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ForgotPassword