import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import "./bootstrap.css";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./screens/Home";
import Profile from "./screens/Profile";
import Logout from "./components/Logout";
import Dashboard from "./screens/adminDashboard/Dashboard";
import Sidebar from "./screens/adminDashboard/Sidebar";
import AdminLogin from "./screens/adminDashboard/AdminLogin";
import Main from "./screens/Main";
import Announcement from "./screens/adminDashboard/Announcement";
import WaitingScreen from "./screens/WaitingScreen";
import ChessBoard from "./screens/ChessBoard";
import { useEffect } from "react";
import { addUser } from "./redux/action";
import { useDispatch } from "react-redux";
import WinningGames from "./screens/WinningGames";
import AllTournaments from "./screens/AllTournaments";
import AllJoinedTeam from "./screens/AllJoinedTeam";
import AllWineners from "./screens/adminDashboard/AllWineners";
import PayU from "./screens/PayU";
import RoundsMatches from "./screens/adminDashboard/RoundsMatches";
import AllTournamentsAdmin from "./screens/adminDashboard/AllTournaments";
import AllPackages from "./screens/adminDashboard/AllPackages";
import PaymentFailure from "./screens/PaymentFailure";
import PaymentSuccess from "./screens/PaymentSuccess";
import PaymentDetails from "./screens/PaymentDetails";
import AllTransaction from "./screens/adminDashboard/AllTransaction";
import ForgotPassword from "./components/ForgotPassword";
// import WithComputer from "./computerGame/WithComputer";

function App() {
  const _id = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const dispatch = useDispatch();
  useEffect(() => {
    if (_id === undefined || _id === "" || _id === null) {
      dispatch(addUser(""));
      return <Login />;
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/logout/:type" element={<Logout />} />
        <Route path="/waiting/:min" Component={WaitingScreen} />
        <Route path="/room/:roomid/:min" Component={ChessBoard} />
        <Route path="/payu" Component={PayU} />
        <Route path="/failure" Component={PaymentFailure} />
        <Route path="/success" Component={PaymentSuccess} />
        <Route path="/forgot-passwords" element={<ForgotPassword />} />
        <Route path="/users" Component={Main}>
          <Route path="home" Component={Home} />
          <Route path="profile" Component={Profile} />
          <Route path="winning-games" Component={WinningGames} />
          <Route path="tournaments" Component={AllTournaments} />
          <Route path="join-tournaments" Component={AllJoinedTeam} />
          <Route path="payment-details" Component={PaymentDetails} />
        </Route>
        <Route path="/admin-login" Component={AdminLogin} />
        <Route path="/admin" Component={Sidebar}>
          <Route index Component={Dashboard} />
          <Route path="dashboard" Component={Dashboard} />
          <Route path="all-tournaments" Component={AllTournamentsAdmin} />
          <Route path="all-winners" Component={AllWineners} />
          <Route path="all-packages" Component={AllPackages} />
          <Route path="set-rounds-matches/:tid/:round" Component={RoundsMatches} />
          <Route path="all-transaction" Component={AllTransaction} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
