import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState("");

  const user = useSelector((state) => state.user.user);
  const _id = localStorage.getItem("userId");

  const logout = () => {
    localStorage.removeItem("userId");
    navigate("/");
    window.location.reload();
  };

  const getUserData = async () => {
    let result = await fetch("https://chessplus.in/api/user_details", {
      method: "POST",
      body: JSON.stringify({ userId: _id }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let res = await result.json();
    if (res.status === 200) {
      setUserData(res.user);
    } else {
      alert(res.message);
    }
  };
  const [address, setAddress] = useState(userData.address);
  const [image, setImage] = useState(userData.profile_image);

  const updateUserData = async () => {
    const formData = new FormData();
    formData.append("userId", userData._id);
    formData.append("address", address);
    formData.append("image", image);
    let result = await fetch("https://chessplus.in/api/edit_profile", {
      method: "POST",
      body: formData,
    });
    let res = await result.json();
    if (res.status === 200) {
      alert(res.message);
      navigate("/users/profile");
      window.location.reload();
    } else {
      alert(res.message);
    }
  };
  useEffect(() => {
    getUserData();
  }, [dispatch]);

  const [toggleB, setToggleB] = useState(false);
  const sidebarToggle = () => {
    if (toggleB === false) {
      setToggleB(true);
    } else {
      setToggleB(false);
    }
  };

  return (
    <>
      <div className="row editProfile">
        <div className="col-lg-8 m-auto">
          <div className="card">
            <div className="card-header text-center">
              <h4> Profile</h4>
            </div>
            <div className="card-body p-4">
              <div className="row ">
                <div className="col-lg-6 col-12 ">
                  <img
                    src={`https://chessplus.in/uploads/${userData.profile_image}`}
                    width={"100%"}
                    alt=""
                  />
                </div>
                <div className="col-lg-6 col-12 ">
                  <p>
                    <strong>Mobile No. :</strong> {userData.mobile}
                  </p>
                  <p>
                    <strong>Name :</strong> {userData.name}
                  </p>
                  <p>
                    <strong>DOB :</strong> {userData.date_of_birth}
                  </p>
                  <p>
                    <strong>Email :</strong> {userData.email}
                  </p>
                  <p><strong>Address :</strong> {userData.address}</p>
                  <p>
                    <strong>UserName :</strong> {userData.username}
                  </p>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-12 col-12 text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Edit Profile
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="row ">
                <div className="col-lg-12 col-12 ">
                  <label className="">Name</label>
                  <input
                    type="text"
                    defaultValue={userData.name}
                    className="form-control"
                    readOnly
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-12 ">
                  <label className="">
                    Mobile No.
                  </label>
                  <input
                    type="number"
                    defaultValue={userData.mobile}
                    className="form-control"
                    readOnly
                  />
                </div>
                <div className="col-lg-6 col-12 ">
                  <label className="">
                    Email
                  </label>
                  <input
                    type="email"
                    defaultValue={userData.email}
                    className="form-control"
                    readOnly
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-12 col-12 ">
                  <label className="">
                    Address
                  </label>
                  <input
                    type="text"
                    defaultValue={userData.address}
                    className="form-control"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-12 ">
                  <label className="">Date Of Birth</label>
                  <input
                    type="date"
                    defaultValue={userData.date_of_birth}
                    className="form-control"
                    readOnly
                  />
                </div>
                <div className="col-lg-6 col-12 ">
                  <label className="">
                    Profile Image <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                    className="form-control"
                  />
                  <img
                    src={`https://chessplus.in/uploads/${userData.profile_image}`}
                    width={"100%"}
                    alt=""
                    className="mt-3"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => updateUserData()}
              >
                Update changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
