import React from 'react'
import { useNavigate } from 'react-router-dom'

const PaymentSuccess = () => {
    const navigate = useNavigate()
    return (
        <div class="container py-5">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="message-box _success shadow">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        <h2> Your payment was successful </h2>
                        <p> Thank you for your payment. we will <br />
                            be in contact with more details shortly </p>
                        <button className='btn btn-primary mt-5' onClick={() => navigate('/users/tournaments')}>Go Back</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess