export const pieceValidMethodMap = (
  prevX,
  prevY,
  x,
  y,
  pieceName,
  pieces,
  piecesOpponent,
  setKingMoved,
  rookMoved,
  setRookMoved,
  castlingRights
) => {


  let validMove = new ValidMove(
    prevX,
    prevY,
    x,
    y,
    pieces,
    piecesOpponent,
    setKingMoved,
    rookMoved,
    setRookMoved,
    castlingRights
  );

  if (pieceName === "p") {
    return validMove.isPawn();
  }

  if (pieceName === "r") {
    return validMove.isRook();
  }

  if (pieceName === "b") {
    return validMove.isBishop();
  }

  if (pieceName === "n") {
    return validMove.isKnight();
  }

  if (pieceName === "q") {
    return validMove.isQueen();
  }

  if (pieceName === "k") {
    return validMove.isKing();
  }
};

class ValidMove {
  constructor(
    prevX,
    prevY,
    x,
    y,
    pieces,
    piecesOpponent,
    setKingMoved,
    rookMoved,
    setRookMoved,
    castlingRights
  ) {
    this.prevX = prevX;
    this.prevY = prevY;
    this.x = x;
    this.y = y;
    this.pieces = pieces;
    this.piecesOpponent = piecesOpponent;
    this.setKingMoved = setKingMoved;
    this.rookMoved = rookMoved;
    this.setRookMoved = setRookMoved;
    this.castlingRights = castlingRights;
  }

  isPawn() {
    if (Math.abs(this.prevY - this.y) > 1) return false;

    // checking if pawn is in starting position pawn can jump 2 steps or else only 1 step
    if (
      (this.prevX === 6 && this.prevX - this.x === 2) ||
      this.prevX - this.x === 1
    ) {
      let prevPos = this.prevX.toString() + ":" + this.prevY.toString();
      let pos = this.x.toString() + ":" + this.y.toString();

      //below condition helps to attack valid pawn attack
      if (
        this.pieces[pos] &&
        this.pieces[pos].color !== this.pieces[prevPos].color &&
        Math.abs(this.prevX - this.x) === 1 &&
        Math.abs(this.prevY - this.y) === 1
      ) {
        return true;
      }

      //below condition helps to valid move for pawn
      if (!this.pieces[pos] && Math.abs(this.prevY - this.y) === 0) return true;
    }

    return false;
  }

  isRook() {
    if (
      Math.abs(this.prevX - this.x) > 0 &&
      Math.abs(this.prevY - this.y) > 0
    ) {
      return false;
    }

    let left = false,
      right = false,
      up = false,
      down = false;

    if (this.prevY - this.y > 0) left = true;
    if (this.prevY - this.y < 0) right = true;
    if (this.prevX - this.x > 0) up = true;
    if (this.prevX - this.x < 0) down = true;

    if (up) {
      for (let i = this.prevX - 1; i > this.x; i--) {
        let pos = i.toString() + ":" + this.y.toString();
        if (this.pieces[pos]) return false;
      }
    }

    if (down) {
      for (let i = this.prevX + 1; i < this.x; i++) {
        let pos = i.toString() + ":" + this.y.toString();
        if (this.pieces[pos]) return false;
      }
    }

    if (right) {
      for (let i = this.prevY + 1; i < this.y; i++) {
        let pos = this.x.toString() + ":" + i.toString();
        if (this.pieces[pos]) return false;
      }
    }

    if (left) {
      for (let i = this.prevY - 1; i > this.y; i--) {
        let pos = this.x.toString() + ":" + i.toString();
        if (this.pieces[pos]) return false;
      }
    }

    return true;
  }

  isBishop() {
    if (Math.abs(this.prevX - this.x) !== Math.abs(this.prevY - this.y)) {
      return false;
    }

    let rightUp = false,
      rightDown = false,
      leftUp = false,
      leftDown = false;

    if (this.prevX - this.x > 0 && this.prevY - this.y > 0) leftUp = true;
    if (this.prevX - this.x > 0 && this.prevY - this.y < 0) rightUp = true;
    if (this.prevX - this.x < 0 && this.prevY - this.y > 0) leftDown = true;
    if (this.prevX - this.x < 0 && this.prevY - this.y < 0) rightDown = true;

    if (leftUp) {
      for (
        let i = this.prevX - 1, j = this.prevY - 1;
        i > this.x && j > this.y;
        i--, j--
      ) {
        let pos = i.toString() + ":" + j.toString();
        if (this.pieces[pos]) return false;
      }
    }

    if (rightUp) {
      for (
        let i = this.prevX - 1, j = this.prevY + 1;
        i > this.x && j < this.y;
        i--, j++
      ) {
        let pos = i.toString() + ":" + j.toString();
        if (this.pieces[pos]) return false;
      }
    }

    if (leftDown) {
      for (
        let i = this.prevX + 1, j = this.prevY - 1;
        i < this.x && j > this.y;
        i++, j--
      ) {
        let pos = i.toString() + ":" + j.toString();
        if (this.pieces[pos]) return false;
      }
    }

    if (rightDown) {
      for (
        let i = this.prevX + 1, j = this.prevY + 1;
        i < this.x && j < this.y;
        i++, j++
      ) {
        let pos = i.toString() + ":" + j.toString();
        if (this.pieces[pos]) return false;
      }
    }

    return true;
  }

  isKnight() {
    if (
      (this.prevX + 2 === this.x && this.prevY + 1 === this.y) ||
      (this.prevX - 2 === this.x && this.prevY + 1 === this.y) ||
      (this.prevX + 2 === this.x && this.prevY - 1 === this.y) ||
      (this.prevX - 2 === this.x && this.prevY - 1 === this.y) ||
      (this.prevX + 1 === this.x && this.prevY + 2 === this.y) ||
      (this.prevX - 1 === this.x && this.prevY + 2 === this.y) ||
      (this.prevX + 1 === this.x && this.prevY - 2 === this.y) ||
      (this.prevX - 1 === this.x && this.prevY - 2 === this.y)
    ) {
      return true;
    }

    return false;
  }

  isQueen() {
    return this.isBishop() || this.isRook();
  }

  isKing() {
    if (
      Math.abs(this.prevX - this.x) <= 1 &&
      Math.abs(this.prevY - this.y) <= 1
    ) {
      this.setKingMoved(true);
      return true;
    }

    // Castling logic
    if (this.prevX === this.x && Math.abs(this.prevY - this.y) === 2) {
      const kingSide = this.y === 6;
      const queenSide = this.y === 2;
      const rookPos = kingSide ? [this.prevX, 7] : [this.prevX, 0];
      const rook = this.pieces[`${rookPos[0]}:${rookPos[1]}`];
      const king = this.pieces[`${this.prevX}:${this.prevY}`];


      if (
        (kingSide || queenSide) &&
        rook &&
        rook.pieceName === "r" &&
        !rook.hasMoved &&
        this.castlingRights
      ) {
        const path = kingSide
          ? [
            [this.prevX, 5],
            [this.prevX, 6]
          ]
          : [
            [this.prevX, 1],
            [this.prevX, 2],
            [this.prevX, 3]
          ];

        if (path.every((pos) => !this.pieces[`${pos[0]}:${pos[1]}`])) {

          // Move the king
          this.pieces[`${this.x}:${this.y}`] = king;
          this.pieces[`${this.prevX}:${this.prevY}`] = null;

          // Move the rook
          const newRookPos = kingSide
            ? [this.x, this.y - 1]
            : [this.x, this.y + 1];
          let a = 0;

          console.log("White Side");
          if (kingSide) {
            // Update opponent's kingside castling pieces
            let c = 3
            let d = 7
            this.piecesOpponent[`${a}:${1}`] = king; //king move on position 0:1
            this.piecesOpponent[`${a}:${this.prevY}`] = null; //king remove on position 0:3
            this.pieces[`${newRookPos[0]}:${newRookPos[1]}`] = rook;
            this.pieces[`${rookPos[0]}:${rookPos[1]}`] = null;
            this.piecesOpponent[`${a}:${2}`] = rook; //rook remove on position 0:2
            this.piecesOpponent[`${a}:${a}`] = null; //rook remove on position 0:3
            this.piecesOpponent[`${a}:${d}`] = rook; //rook remove on position 0:3
            this.piecesOpponent[`${a}:${c}`] = null; //rook remove on position 0:3
            return true;
          } else if (queenSide) {
            let b = 5;
            let c = 3
            let d = 7
            // Update opponent's queenside castling pieces
            this.pieces[`${newRookPos[0]}:${newRookPos[1]}`] = rook;
            this.pieces[`${rookPos[0]}:${rookPos[1]}`] = null;
            this.piecesOpponent[`${a}:${b}`] = king; //king move on position 0:1
            this.piecesOpponent[`${a}:${this.prevY}`] = rook; //king remove on position 0:3
            this.piecesOpponent[`${a}:${a}`] = rook; //rook remove on position 0:2
            this.piecesOpponent[`${a}:${d}`] = null; //rook remove on position 0:3
            this.piecesOpponent[`${a}:${c}`] = null; //rook remove on position 0:3
            return true;
          }

          // Update movement states
          this.setKingMoved(true);
          this.setRookMoved({
            ...this.rookMoved,
            [kingSide ? "kingside" : "queenside"]: true,
          });
        }
      } else {
        const kingSide = this.y === 1;
        const queenSide = this.y === 5;
        const path = kingSide
          ? [
            [this.prevX, 1],
            [this.prevX, 2]
          ]
          : [
            [this.prevX, 4],
            [this.prevX, 5],
            [this.prevX, 6]
          ];

        if (path.every((pos) => !this.pieces[`${pos[0]}:${pos[1]}`])) {

          // Move the king
          this.pieces[`${this.x}:${this.y}`] = king;
          this.pieces[`${this.prevX}:${this.prevY}`] = null;

          // Move the rook
          const newRookPos = kingSide
            ? [this.x, this.y + 1]
            : [this.x, this.y - 1];
          let a = 0;

          console.log("Black Side");
          if (kingSide) {
            // Update opponent's kingside castling pieces
            let b = 7;
            let c = 5
            let d = 6;
            let e = 4;
            this.piecesOpponent[`${a}:${d}`] = king; //king move on position 0:5
            this.piecesOpponent[`${a}:${this.prevY}`] = null; //king remove on position 0:3
            this.piecesOpponent[`${a}:${e}`] = null; //king remove on position 0:4
            this.pieces[`${newRookPos[0]}:${newRookPos[1]}`] = rook;
            this.pieces[`${rookPos[0]}:${rookPos[1]}`] = null;
            this.piecesOpponent[`${a}:${c}`] = rook; //rook remove on position 0:5
            this.piecesOpponent[`${a}:${b}`] = null; //rook remove on position 0:7
            this.piecesOpponent[`${a}:${a}`] = rook; //rook remove on position 0:0
            return true;
          } else if (queenSide) {
            let b = 2;
            let c = 7
            let e = 4;

            // Update opponent's queenside castling pieces
            this.pieces[`${newRookPos[0]}:${newRookPos[1]}`] = rook;
            this.pieces[`${rookPos[0]}:${c}`] = null;
            this.piecesOpponent[`${a}:${b}`] = king; //king move on position 0:1
            this.piecesOpponent[`${a}:${this.prevY}`] = rook; //king remove on position 0:3
            this.piecesOpponent[`${a}:${c}`] = rook; //rook remove on position 0:2
            this.piecesOpponent[`${a}:${a}`] = null; //rook remove on position 0:3
            this.piecesOpponent[`${a}:${e}`] = null; //rook remove on position 0:3
            return true;
          }


          // Update movement states
          this.setKingMoved(true);
          this.setRookMoved({
            ...this.rookMoved,
            [kingSide ? "kingside" : "queenside"]: true,
          });

          return true;
        }
      }
    }


    return false;
  }
}


// export const isValidMoveForCheckMate = (kingPosX, kingPosY, pieces) => {
//   let grabPos = kingPosX.toString() + ":" + kingPosY.toString();

//   console.log("checkmate->" + grabPos);

//   let checkMateCount = 0;

//   try {
//     // up

//     for (let i = kingPosX - 1; i >= 0; i--) {
//       let pos = i.toString() + ":" + kingPosY.toString();

//       if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) {
//         break;
//       }

//       if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
//         if (pieces[pos].pieceName === "r" || pieces[pos].pieceName === "q") {
//           console.log("up");

//           checkMateCount++;
//           break;
//         } else break;
//       }
//     }

//     // down

//     for (let i = kingPosX + 1; i < 8; i++) {
//       let pos = i.toString() + ":" + kingPosY.toString();

//       if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) {
//         break;
//       }

//       if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
//         if (pieces[pos].pieceName === "r" || pieces[pos].pieceName === "q") {
//           console.log("down");
//           checkMateCount++;
//           break;
//         } else break;
//       }
//     }

//     // left

//     for (let i = kingPosY - 1; i >= 0; i--) {
//       let pos = kingPosX.toString() + ":" + i.toString();

//       if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) {
//         break;
//       }

//       if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
//         if (pieces[pos].pieceName === "r" || pieces[pos].pieceName === "q") {
//           console.log("left");
//           checkMateCount++;
//           break;
//         } else break;
//       }
//     }
//     // right

//     for (let i = kingPosY + 1; i < 8; i++) {
//       let pos = kingPosX.toString() + ":" + i.toString();

//       if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) {
//         break;
//       }

//       if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
//         if (pieces[pos].pieceName === "r" || pieces[pos].pieceName === "q") {
//           console.log("right");
//           checkMateCount++;
//           break;
//         } else break;
//       }
//     }

//     // leftup

//     for (let i = kingPosX - 1, j = kingPosY - 1; i >= 0 && j >= 0; i--, j--) {
//       let pos = i.toString() + ":" + j.toString();

//       if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) {
//         break;
//       }

//       if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
//         if (
//           pieces[pos].pieceName === "b" ||
//           pieces[pos].pieceName === "q" ||
//           (pieces[pos].pieceName === "p" &&
//             kingPosX - i === 1 &&
//             kingPosY - j === 1)
//         ) {
//           console.log("leftup");
//           checkMateCount++;
//           break;
//         } else break;
//       }
//     }

//     // leftDown

//     for (let i = kingPosX + 1, j = kingPosY - 1; i < 8 && j >= 0; i++, j--) {
//       let pos = i.toString() + ":" + j.toString();

//       if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) {
//         break;
//       }

//       if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
//         if (pieces[pos].pieceName === "b" || pieces[pos].pieceName === "q") {
//           console.log("leftdown");
//           checkMateCount++;
//           break;
//         } else break;
//       }
//     }

//     // RightUp

//     for (let i = kingPosX - 1, j = kingPosY + 1; i >= 0 && j < 8; i--, j++) {
//       let pos = i.toString() + ":" + j.toString();

//       if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) {
//         break;
//       }

//       if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
//         if (
//           pieces[pos].pieceName === "b" ||
//           pieces[pos].pieceName === "q" ||
//           (pieces[pos].pieceName === "p" &&
//             kingPosX - i === 1 &&
//             j - kingPosY === 1)
//         ) {
//           console.log("rightup");
//           checkMateCount++;
//           break;
//         } else break;
//       }
//     }

//     // RightDown

//     for (let i = kingPosX + 1, j = kingPosY + 1; i < 8 && j < 8; i++, j++) {
//       let pos = i.toString() + ":" + j.toString();

//       if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) {
//         break;
//       }

//       if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
//         if (pieces[pos].pieceName === "b" || pieces[pos].pieceName === "q") {
//           console.log("rightdown");
//           checkMateCount++;
//           break;
//         } else break;
//       }
//     }

//     // knight attack

//     let allKnightPos = [
//       [2, 1],
//       [-2, 1],
//       [2, -1],
//       [-2, -1],
//       [1, 2],
//       [-1, 2],
//       [-1, -2],
//       [1, -2],
//     ];

//     for (let i = 0; i < 8; i++) {
//       let pos =
//         (kingPosX + allKnightPos[i][0]).toString() +
//         ":" +
//         (kingPosY + allKnightPos[i][1]).toString();

//       if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) {
//         continue;
//       }

//       if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
//         if (pieces[pos].pieceName === "n") {
//           console.log("knight");
//           checkMateCount++;
//           break;
//         } else break;
//       }
//     }
//     return false;
//   } catch (e) {
//     console.log("Error while validating move for checkmate", e.message);
//   }

//   // console.log(checkMateCount)

//   return checkMateCount;
// };

export const isValidMoveForCheckMate = (kingPosX, kingPosY, pieces) => {
  let grabPos = kingPosX.toString() + ":" + kingPosY.toString();
  let checkMateCount = 0;
  let checkMateMessages = [];

  console.log("checkmate->" + grabPos);

  try {
    // up
    for (let i = kingPosX - 1; i >= 0; i--) {
      let pos = i.toString() + ":" + kingPosY.toString();
      if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) break;
      if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
        if (pieces[pos].pieceName === "r" || pieces[pos].pieceName === "q") {
          console.log("up");
          checkMateCount++;
          checkMateMessages.push("Checkmate from the top.");
          break;
        } else break;
      }
    }

    // down
    for (let i = kingPosX + 1; i < 8; i++) {
      let pos = i.toString() + ":" + kingPosY.toString();
      if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) break;
      if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
        if (pieces[pos].pieceName === "r" || pieces[pos].pieceName === "q") {
          console.log("down");
          checkMateCount++;
          checkMateMessages.push("Checkmate from the bottom.");
          break;
        } else break;
      }
    }

    // left
    for (let i = kingPosY - 1; i >= 0; i--) {
      let pos = kingPosX.toString() + ":" + i.toString();
      if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) break;
      if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
        if (pieces[pos].pieceName === "r" || pieces[pos].pieceName === "q") {
          console.log("left");
          checkMateCount++;
          checkMateMessages.push("Checkmate from the left.");
          break;
        } else break;
      }
    }

    // right
    for (let i = kingPosY + 1; i < 8; i++) {
      let pos = kingPosX.toString() + ":" + i.toString();
      if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) break;
      if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
        if (pieces[pos].pieceName === "r" || pieces[pos].pieceName === "q") {
          console.log("right");
          checkMateCount++;
          checkMateMessages.push("Checkmate from the right.");
          break;
        } else break;
      }
    }

    // leftup
    for (let i = kingPosX - 1, j = kingPosY - 1; i >= 0 && j >= 0; i--, j--) {
      let pos = i.toString() + ":" + j.toString();
      if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) break;
      if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
        if (
          pieces[pos].pieceName === "b" ||
          pieces[pos].pieceName === "q" ||
          (pieces[pos].pieceName === "p" && kingPosX - i === 1 && kingPosY - j === 1)
        ) {
          console.log("leftup");
          checkMateCount++;
          checkMateMessages.push("Checkmate from the top-left diagonal.");
          break;
        } else break;
      }
    }

    // leftDown
    for (let i = kingPosX + 1, j = kingPosY - 1; i < 8 && j >= 0; i++, j--) {
      let pos = i.toString() + ":" + j.toString();
      if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) break;
      if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
        if (pieces[pos].pieceName === "b" || pieces[pos].pieceName === "q") {
          console.log("leftdown");
          checkMateCount++;
          checkMateMessages.push("Checkmate from the bottom-left diagonal.");
          break;
        } else break;
      }
    }

    // RightUp
    for (let i = kingPosX - 1, j = kingPosY + 1; i >= 0 && j < 8; i--, j++) {
      let pos = i.toString() + ":" + j.toString();
      if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) break;
      if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
        if (
          pieces[pos].pieceName === "b" ||
          pieces[pos].pieceName === "q" ||
          (pieces[pos].pieceName === "p" && kingPosX - i === 1 && j - kingPosY === 1)
        ) {
          console.log("rightup");
          checkMateCount++;
          checkMateMessages.push("Checkmate from the top-right diagonal.");
          break;
        } else break;
      }
    }

    // RightDown
    for (let i = kingPosX + 1, j = kingPosY + 1; i < 8 && j < 8; i++, j++) {
      let pos = i.toString() + ":" + j.toString();
      if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) break;
      if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
        if (pieces[pos].pieceName === "b" || pieces[pos].pieceName === "q") {
          console.log("rightdown");
          checkMateCount++;
          checkMateMessages.push("Checkmate from the bottom-right diagonal.");
          break;
        } else break;
      }
    }

    // knight attack
    let allKnightPos = [
      [2, 1],
      [-2, 1],
      [2, -1],
      [-2, -1],
      [1, 2],
      [-1, 2],
      [-1, -2],
      [1, -2],
    ];

    for (let i = 0; i < allKnightPos.length; i++) {
      let pos = (kingPosX + allKnightPos[i][0]).toString() + ":" + (kingPosY + allKnightPos[i][1]).toString();
      if (pieces[pos] && pieces[pos].color === pieces[grabPos].color) continue;
      if (pieces[pos] && pieces[pos].color !== pieces[grabPos].color) {
        if (pieces[pos].pieceName === "n") {
          console.log("knight");
          checkMateCount++;
          checkMateMessages.push("Checkmate by a knight.");
          break;
        } else break;
      }
    }
    return false
  } catch (e) {
    console.log("Error while validating move for checkmate", e.message);
  }
  // if (checkMateCount > 0) {
  console.log("Checkmate message", checkMateCount);
  //   return checkMateCount;
  // }
  return checkMateCount;

  //   // Return the final result with checkmate count and detailed messages
  //   return {
  //     checkMateCount,
  //     checkMateMessages,
  //     isCheckMate: checkMateCount > 0
  //   };

  // } catch (e) {
  //   console.log("Error while validating move for checkmate", e.message);
  //   return {
  //     checkMateCount: 0,
  //     checkMateMessages: [],
  //     isCheckMate: false,
  //   };
  // }
};



const pieces1 = {
  "2:3": { pieceName: "k", color: "b" },
  "0:2": { pieceName: "k", color: "w" },
  "7:4": { pieceName: "q", color: "w" },
  "2:2": { pieceName: "r", color: "w" },
  "7:2": { pieceName: "r", color: "w" },
  "7:6": { pieceName: "r", color: "b" },
  "1:6": { pieceName: "r", color: "b" },
  "1:0": { pieceName: "p", color: "w" },
  "1:3": { pieceName: "p", color: "w" },
  "5:0": { pieceName: "p", color: "b" },
  "3:7": { pieceName: "p", color: "b" },
};

console.log(isValidMoveForCheckMate(2, 3, pieces1)); // Expected output: 3 (Checkmate count)



