import { useState, useEffect } from "react";
import Check from "@mui/icons-material/Check";
// import styled from "styled-components";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/material";

const CheckoutButton = styled(Button)`
  && {
    background-color: green;
    color: white;
  }
`;
const PayU = () => {
    const [self, setSelf] = useState();
    const [oncheckOpen, setOnCheckOpen] = useState(false);
    let amount = 100; //update amount
    let contact = 9112060685; //update contact no.
    let url = "http://localhost:8000";

    const data = {
        txnid: "ord_12345", //String
        amount: amount.toFixed(2),  //Float
        productinfo: "This is Testing Product",  //String
        firstname: "Rajesh",   //String
        email: "rajesh@ewebdigital.com",  //String
    };

    useEffect(() => {
        // makePayment();
    }, []);

    const makePayment = async () => {
        await paymentReq();
        // await responseReq();
    };
    const [hash, setHash] = useState("");
    //This method will generate the hashvalue
    const paymentReq = async () => {

        const reshash = await fetch(`${url}/api/payment`, {
            method: "post",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        });
        let result = await reshash.json();
        if (result) {
            setHash(result);
            await responseReq(result);
        }
    }

    //We are using a modal, to open the payment transaction gateway after a click 
    const handleCheckClose = () => setOnCheckOpen(false); //close the modal
    const handleCheckOpen = () => setOnCheckOpen(true);  //open the modal

    //This method will use that hash value to make the transaction complete
    const responseReq = async (result) => {
        const pd = {
            key: "SIg1iy", //once testing is done, change it to live key
            txnid: "ord_12345", //String,
            amount: amount.toFixed(2),  //Float
            productinfo: "This is Testing Product",  //String
            firstname: "Rajesh",
            email: "rajesh@ewebdigital.com",
            phone: contact,
            productinfo: "This is Testing Product",
            surl: "http://localhost:8000/api/payment_success",
            furl: "http://localhost:8000/api/payment_failure",
            hash: result.hash,  //hashvalue 
            service_provider: "payu_paisa",
        };
        let res;
        try {
            res = await axios.post(`${url}/api/payment_response`, JSON.stringify(pd), {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            console.log(res);

            await setSelf(res.data);
            await handleCheckOpen();
            return res;
        } catch (err) {
            console.log("response error");
        }
    };
    return (
        <div className="text-center">
            <h1>Hello</h1>
            <button className="btn btn-primary" onClick={() => makePayment()}>Pay Now</button>
            <Modal
                open={oncheckOpen}
                onClose={handleCheckClose}
                closeAfterTransition
                style={{
                    background: "white",
                    width: "50%",
                    margin: "auto",
                    height: "20vh",
                }}
            >
                <div
                    style={{
                        backgroundColor: "white",
                        height: "20vh",
                        padding: "8%",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                    }}
                >
                    <a
                        href={self}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <Button style={{ margin: "auto", alignItems: "center" }}>
                            You'll be redirected to PayU payment Gateway | <Check />
                        </Button>
                    </a>
                </div>
            </Modal>
        </div >
    );
};
export default PayU