import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addUser } from "../redux/action";
import { useDispatch } from "react-redux";
import { url } from "../helpers/apiHelpers";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [dob, setDOB] = useState("");
  const [image, setImage] = useState("");

  const StoreData = async () => {
    if (!firstName || !lastName || !mobile || !email || !image || !password) {
      alert("Fill all Mandatory Fields");
      return;
    } else {
      let fullName = firstName + " " + middleName + " " + lastName;
      let fatherName = "";
      if (!middleName) {
        fatherName = middleName + " " + lastName;
      }
      const formData = new FormData();
      formData.append("name", firstName);
      formData.append("fullName", fullName);
      formData.append("fatherName", fatherName);
      formData.append("mobile", mobile);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("dob", dob);
      formData.append("image", image);

      const result = await fetch("https://chessplus.in/api/add_user", {
        method: "POST",
        body: formData,
      });
      let res = await result.json();
      if (res.status === 200) {
        localStorage.setItem("userId", res.user._id);
        localStorage.setItem("username", res.user.username);
        dispatch(addUser({ username: res.user.username }));
        alert(res.message);
        navigate("/");
      } else {
        alert(res.message);
      }
    }
  };

  const [passValid, setPassValid] = useState(false);
  const passwordValidation = (cpass) => {
    if (password === cpass) {
      setCPassword(cpass);
      setPassValid(true);
    } else {
      setPassValid(false);
    }
  };

  return (
    <div
      className="w-100 admin-Login-bg "
      style={{
        backgroundImage: `url(${url}/img/bg-image.jpg)`,
        backgroundSize: "cover",
      }}
    >
      <div className="container px-0 p-3">
        <div className="position-relative">
          <div className="row m-lg-5 m-3 no-gutters shadow">
            <div className="col-md-6 px-0">
              <img
                src={`${url}/img/chess2.jpg`}
                className="img-fluid"
                style={{ minHeight: "100%" }}
              />
            </div>
            <div className="col-md-6 bg-white px-4 py-5 form-style">
              <div className="row ">
                <div className="col-lg-4 col-12 form-group">
                  <input
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                    className="form-control"
                    placeholder="First Name *"
                  />
                </div>
                <div className="col-lg-4 col-12 form-group">
                  <input
                    type="text"
                    onChange={(e) => setMiddleName(e.target.value)}
                    className="form-control"
                    placeholder="Middle Name"
                  />
                </div>
                <div className="col-lg-4 col-12 form-group">
                  <input
                    type="text"
                    onChange={(e) => setLastName(e.target.value)}
                    className="form-control"
                    placeholder="Last Name *"
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-6 col-12 form-group">
                  <input
                    type="number"
                    onChange={(e) => setMobile(e.target.value)}
                    className="form-control"
                    placeholder="Mobile Number *"
                  />
                </div>
                <div className="col-lg-6 col-12 form-group">
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    placeholder="Email *"
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-6 col-12 form-group">
                  <input
                    type="date"
                    onChange={(e) => setDOB(e.target.value)}
                    className="form-control"
                    placeholder="Date of Birth *"
                  />
                </div>
                <div className="col-lg-6 col-12 form-group">
                  <input
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-6 col-12 form-group">
                  <input
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                    placeholder="Password *"
                  />
                </div>
                <div className="col-lg-6 col-12 form-group">
                  <input
                    type="password"
                    onChange={(e) => passwordValidation(e.target.value)}
                    className="form-control"
                    placeholder="Confirm Password *"
                  />
                </div>
              </div>

              <div className="row ">
                <div className="col-lg-12 my-3 login-btm login-button text-center">
                  <button
                    type="button"
                    onClick={() => StoreData()}
                    className="btn btn-outline-primary w-50"
                    disabled={passValid === true ? false : true}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="row  mt-1">
                <div className="col-lg-12 text-center">
                  <p className="text-dark">
                    {" "}
                    If you have an already account?
                    <Link to={"/"} className="text-info">
                      {" "}
                      Please Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="App pb-5 LoginRegister">
      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 m-auto login-box">
            <div className="col-lg-12 login-title mt-2">Create Account</div>

            <div className="login-form">
              <div className="row ">
                <div className="col-lg-4 col-12 form-group">
                  <label className="form-control-label">
                    First Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-4 col-12 form-group">
                  <label className="form-control-label">Middle Name</label>
                  <input
                    type="text"
                    onChange={(e) => setMiddleName(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-4 col-12 form-group">
                  <label className="form-control-label">
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setLastName(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-6 col-12 form-group">
                  <label className="form-control-label">
                    Mobile No. <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    onChange={(e) => setMobile(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-6 col-12 form-group">
                  <label className="form-control-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-6 col-12 form-group">
                  <label className="form-control-label">Date Of Birth</label>
                  <input
                    type="date"
                    onChange={(e) => setDOB(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-6 col-12 form-group">
                  <label className="form-control-label">
                    Profile Image <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-6 col-12 form-group">
                  <label className="form-control-label">
                    Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-lg-6 col-12 form-group">
                  <label className="form-control-label">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    onChange={(e) => passwordValidation(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="row ">
                <div className="col-lg-12 mb-4 login-btm login-button text-center">
                  <button
                    type="button"
                    onClick={() => StoreData()}
                    className="btn btn-outline-info"
                    disabled={passValid === true ? false : true}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="row  mt-1">
                <div className="col-lg-12 text-center">
                  <p className="text-white">
                    {" "}
                    If you have an already account?
                    <Link to={"/"} className="text-info">
                      {" "}
                      Please Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
