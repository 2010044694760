import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { url } from "../../helpers/apiHelpers";
const AllPackages = () => {
    const [PackagesDialog, setPackagesDialog] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [pageRender, setPageRender] = useState(false);
    const [packagesTitle, setPackagesTitle] = useState("");
    const [packagesAmount, setPackagesAmount] = useState("");
    const [packagesDescription, setPackagesDescription] = useState("");

    const [packages, setPackages] = useState(null);
    const getPackages = async () => {
        const result = await fetch(`${url}/api/get_packages`);
        const response = await result.json();
        if (response.status === 200) {
            setPackages(response.result);
        } else {
            alert(response.message);
        }
    };

    useEffect(() => {
        getPackages();
    }, [pageRender]);

    const openNew = () => {
        setPackagesDialog(true);
    };

    const hideDialog = () => {
        setPackagesDialog(false);
    };


    const exportCSV = () => {
        dt.current.exportCSV();
    };
    const rightToolbarTemplate = () => {
        return (
            <Button
                label="Export"
                icon="pi pi-upload"
                className="p-button-help"
                onClick={exportCSV}
            />
        );
    };
    const deletePackage = async (id) => {
        try {
            const result = await fetch(`${url}/api/delete_package`, {
                method: "POST",
                body: JSON.stringify({
                    packageId: id,
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const res = await result.json();
            if (res.status === 200) {
                setPageRender(true);
                alert(res.result);
                hideDialog()
            } else {
                setPageRender(false);
                alert(res.result);
            }
        } catch (error) {

        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-flex announcementButton">
                <Button
                    icon="pi pi-trash"
                    rounded
                    outlined
                    severity="danger"
                    onClick={() => deletePackage(rowData._id)}
                />
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">All Packages</h4>
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                />
            </IconField>
            <div className="flex flex-wrap gap-2">
                <Button
                    label="Add"
                    icon="pi pi-plus"
                    severity="success"
                    className="btn-sm"
                    onClick={openNew}
                />
            </div>
        </div>
    );
    const StorePackages = async () => {
        try {
            const result = await fetch(`${url}/api/store_package`, {
                method: "POST",
                body: JSON.stringify({
                    packageTitle: packagesTitle,
                    packageDescription: packagesDescription,
                    packageAmount: packagesAmount
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const res = await result.json();
            if (res.status === 200) {
                setPageRender(true);
                alert(res.result);
                hideDialog()
            } else {
                setPageRender(false);
                alert(res.result);
            }
        } catch (error) {

        }
    }
    const PackagesDialogFooter = (
        <React.Fragment>
            <Button label="Submit" icon="pi pi-plus" severity="primary" outlined onClick={StorePackages} />
            <Button label="Cancel" icon="pi pi-times" className="ms-2" severity="danger" outlined onClick={hideDialog} />
        </React.Fragment>
    );
    return (
        <div className="AllPackages">
            <Toast ref={toast} />
            <div className="card">
                <DataTable
                    ref={dt}
                    value={packages}
                    selection={selectedProducts}
                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Data"
                    globalFilter={globalFilter}
                    header={header}
                >
                    <Column field="createdAt" header="Date" sortable></Column>
                    <Column field="packageTitle" header="Package Name" sortable></Column>
                    <Column field="packageDescription" header="Package Description" sortable></Column>
                    <Column field="packageAmount" header="Package Amount" sortable></Column>
                    <Column field="status" header="status" bodyStyle={{ color: 'green', fontWeight: "bold" }} sortable></Column>
                    <Column body={actionBodyTemplate} exportable={false}></Column>

                </DataTable>
            </div>

            <Dialog
                visible={PackagesDialog}
                style={{ width: "32rem" }}
                breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                header="Packages Add"
                modal
                className="p-fluid"
                footer={PackagesDialogFooter}
                onHide={hideDialog}
            >
                <div className="row">
                    <div className="col-lg-8 col-12 mt-2">
                        <label>Package Title</label>
                        <input type="text" className="form-control" name='title' onChange={(e) => setPackagesTitle(e.target.value)} placeholder="Package Title" />
                    </div>
                    <div className="col-lg-4 col-12 mt-2">
                        <label>Package Amount</label>
                        <input type="number" className="form-control" name='amount' onChange={(e) => setPackagesAmount(e.target.value)} placeholder="Package Amount" />
                    </div>
                    <div className="col-lg-12 col-12 mt-2">
                        <label>Package Description</label>
                        <input type="text" className="form-control" name='description' onChange={(e) => setPackagesDescription(e.target.value)} placeholder="Package Description" />
                    </div>
                </div>

            </Dialog>

        </div>
    );
}

export default AllPackages