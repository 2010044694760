import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Logout = () => {
  const param = useParams();
  const type = param.type;
  const navigate = useNavigate();
  const getLogout = () => {
    if (type === "Admin") {
      localStorage.removeItem("adminId");
      localStorage.removeItem("adminType");
      localStorage.removeItem("adminName");
      navigate("/admin-login");
      window.location.reload();
    } else {
      localStorage.removeItem("userId");
      localStorage.removeItem("userType");
      localStorage.removeItem("username");
      navigate("/");
      window.location.reload();
    }
  };
  useEffect(() => {
    getLogout();
  }, []);
};

export default Logout;
