import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { socket, url } from "../helpers/apiHelpers";
import axios from "axios";
import { addUser } from "../redux/action";
import moment from "moment"
import Check from "@mui/icons-material/Check";
import { Button, Modal } from "@mui/material";

const AllTournaments = () => {
  const [tournament, setTournament] = useState(null);
  const [joinedStatus, setJoinedStatus] = useState({});
  const [userData, setUserData] = useState("");

  const getTournaments = async () => {
    const result = await fetch(`${url}/api/get_tournament`);
    const response = await result.json();
    if (response.status === 200) {
      setTournament(response.result);
    } else {
      alert(response.message);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [orderId, setOrderId] = useState("ORD" + Math.floor(100000 + Math.random() * 900000));

  const user = useSelector((state) => state.user.user);
  const _id = localStorage.getItem("userId");

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await axios.post(`${url}/api/get-user`, {
          _id,
        });

        const data = response.data;
        setUserData(data.user)
        dispatch(addUser(data.user));

        if (data.user?.isInGame) {
          socket.emit("reconnection", { roomId: data.user.activeGameRoomId });
          // navigate(`/room/${data.user.activeGameRoomId}`);
        }
      } catch (e) {
        console.log("Error while fetching user details", e.message);
      }
    };
    getUserDetails();
  }, [dispatch, _id]);


  console.log(userData);


  const getJoinedStatus = async () => {
    const status = {};
    for (const item of tournament) {
      const result = await fetch("https://chessplus.in/api/get_joined_single_team", {
        method: "POST",
        body: JSON.stringify({ userId: _id, tournamentId: item._id }),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const response = await result.json();
      if (response.status === 200) {
        status[item._id] = response.result;
      } else {
        status[item._id] = false;
      }
    }
    setJoinedStatus(status);
  }
  useEffect(() => {
    getTournaments();

  }, []);

  useEffect(() => {
    if (tournament) {
      getJoinedStatus();
    }
  }, [tournament]);

  const date = new Date();
  const currentDate = moment(date).format("YYYY-MM-DD");

  const [self, setSelf] = useState();
  const [oncheckOpen, setOnCheckOpen] = useState(false);


  const [hash, setHash] = useState("");
  const [selectedData, setSelectedData] = useState("");

  const makePayment = async (item) => {
    const amt = parseInt(item.packageAmount);
    setSelectedData(item)
    const data = {
      txnid: orderId, //String
      amount: amt,  //Float
      productinfo: item._id,  //String
      firstname: userData.name,   //String
      email: userData.email,  //String
      udf1: item.title,
      udf2: item.timeControl,
      udf3: item.joiningStartDate,
      udf4: item.joiningEndDate,
      udf5: userData._id,
      udf6: userData.mobile,
      udf7: userData.name,
    };
    const reshash = await fetch(`${url}/api/payment`, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = await reshash.json();
    if (result) {
      setHash(result.hash);
      // await responseReq(result, data);
      await handleCheckOpen();
    }
  };

  //We are using a modal, to open the payment transaction gateway after a click 
  const handleCheckClose = () => setOnCheckOpen(false); //close the modal
  const handleCheckOpen = () => setOnCheckOpen(true);  //open the modal

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 col-12 mb-3">
          <h3>All Tournaments</h3>
          <hr className="my-0" />
        </div>
        <Modal
          open={oncheckOpen}
          onClose={handleCheckClose}
          closeAfterTransition
          style={{
            background: "#ffffff00",
            width: "50%",
            margin: "auto",
            height: '20%'
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "3%",
              marginTop: '5%',
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              boxShadow: '0 0 10px green'
            }}
          >
            <form action='https://test.payu.in/_payment' method='post'>
              <input type="hidden" className="form-control" name="key" value="SIg1iy" />
              <input type="hidden" className="form-control" name="txnid" value={orderId} />
              <input type="hidden" className="form-control" name="productinfo" value={selectedData?._id} />
              <input type="hidden" className="form-control" name="amount" value={selectedData?.packageAmount} />
              <input type="hidden" className="form-control" name="email" value={userData?.email} />
              <input type="hidden" className="form-control" name="firstname" value={userData?.name} />
              <input type="hidden" className="form-control" name="surl" value="https://chessplus.in/api/response/test" />
              <input type="hidden" className="form-control" name="furl" value="https://chessplus.in/api/response/test" />
              <input type="hidden" className="form-control" name="phone" value={userData?.mobile} />
              <input type="hidden" className="form-control" name="hash" value={hash} />
              <input type="hidden" className="form-control" name="udf1" value={selectedData?.title} />
              <input type="hidden" className="form-control" name="udf2" value={selectedData?.timeControl} />
              <input type="hidden" className="form-control" name="udf3" value={selectedData?.joiningStartDate} />
              <input type="hidden" className="form-control" name="udf4" value={selectedData?.joiningEndDate} />
              <input type="hidden" className="form-control" name="udf5" value={userData?._id} />
              <input type="hidden" className="form-control" name="udf6" value={userData?.mobile} />
              <input type="hidden" className="form-control" name="udf7" value={userData?.name} />
              <button type="submit" className="btn btn-primary btn-lg mt-3" >
                You'll be redirected to PayU payment Gateway | <Check />
              </button>
            </form>
            {/* <a
              href={self}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button style={{ margin: "auto", alignItems: "center", fontWeight: 'bold', fontSize: 18 }}>
                You'll be redirected to PayU payment Gateway | <Check />
              </Button>
            </a> */}
          </div>
        </Modal>
        {tournament &&
          tournament.map((item) => {
            return (
              <div className="col-lg-3 col-6 col-md-4" key={item._id}>
                <div className="card rounded-0 all-tournament" >
                  <div className="card-body p-0">
                    <img
                      src={`https://chessplus.in/uploads/${item.image}`}
                      alt=""
                      width={"100%"}
                    />
                  </div>
                  <div className="card-footer bg-danger py-2 px-3 text-center">
                    <p className="fw-bold mb-0 text-white">{item.title}</p>
                    <p className="fw-bold mb-0 text-white"><i className="fa fa-rupee-sign"></i> {item.packageAmount}</p>
                    <p className="my-0 text-light">
                      {item.joiningStartDate + " - " + item.joiningEndDate}
                    </p>
                    {/* <div className="mt-2">
                      {
                        joinedButton(item._id) === false ? (<button className="btn btn-primary btn-sm" onClick={() => joinTournaments(item)}>Join Now</button>) : (<button className="btn btn-primary btn-sm">Joined</button>)
                      }
                    </div> */}
                    {
                      currentDate > item.joiningEndDate ?
                        "" :
                        <div className="mt-2">
                          {joinedStatus[item._id] === false ? (
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => makePayment(item)}
                            >
                              Join Now
                            </button>
                          ) : (
                            <button className="btn btn-primary btn-sm">Joined</button>
                          )}
                        </div>

                    }


                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AllTournaments;
