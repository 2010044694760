import React, { useEffect, useState } from "react";

const WinningGames = () => {
  const [data, setData] = useState([]);
  const userId = localStorage.getItem("userId");
  const getGameWinningData = async () => {
    const result = await fetch("https://chessplus.in/api/get_winner_data", {
      method: "POST",
      body: JSON.stringify({
        winnerId: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await result.json();
    if (res.status === 200) {
      setData(res.result);
    } else {
      alert(res.message);
    }
  };

  useEffect(() => {
    getGameWinningData();
  }, []);
  return (
    <>
      <div className="row WinningPage">
        <div className="col-lg-12 col-12 mb-3">
          <h3>All Winning Data</h3>
          <hr className="my-0" />
        </div>
        {data &&
          data.map((item) => {
            const OpponentData = item.teamData[1];
            return (
              <div className="col-lg-6 col-12">
                <div className="card p-3 rounded-3 bg-primary">
                  <div className="row">
                    <div className="col-lg-12">
                      <strong>Room ID </strong>
                      <strong>: </strong>
                      {item.roomid}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      <strong>Winner Id </strong>
                      <strong>: </strong>
                      {item.winnerId}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      <strong>Winner Name </strong>
                      <strong>: </strong>
                      {item.winnerName}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      <strong>Winner Mobile </strong>
                      <strong>: </strong>
                      {item.winnerMobile}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      <strong>Piece Color </strong>
                      <strong>: </strong>
                      {item.color === "b" ? (
                        <b className="text-white bg-dark">
                          {" "}
                          &nbsp; Black &nbsp;{" "}
                        </b>
                      ) : (
                        <b className="text-dark bg-white">
                          {" "}
                          &nbsp; White &nbsp;{" "}
                        </b>
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      <h5 className="text-white">
                        <strong>Opponent </strong>
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <strong>Room ID </strong>
                      <strong>: </strong>
                      {item.roomid}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      <strong>Opponent Name </strong>
                      <strong>: </strong>
                      {OpponentData.username}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      <strong>Opponent Id </strong>
                      <strong>: </strong>
                      {OpponentData.userId}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      <strong>Piece Color </strong>
                      <strong>: </strong>
                      {OpponentData.color === "b" ? (
                        <b className="text-white bg-dark">
                          {" "}
                          &nbsp; Black &nbsp;{" "}
                        </b>
                      ) : (
                        <b className="text-dark bg-light">
                          {" "}
                          &nbsp; White &nbsp;{" "}
                        </b>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default WinningGames;
