import React from "react";

const Dashboard = () => {
  return (
    <div className="row">
      <div className="col-lg-4">
        <div className="card p-3 bg-primary"></div>
      </div>
      <div className="col-lg-4">
        <div className="card p-3 bg-primary"></div>
      </div>
      <div className="col-lg-4">
        <div className="card p-3 bg-primary"></div>
      </div>
    </div>
  );
};

export default Dashboard;
