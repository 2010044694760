import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { socket } from "../helpers/apiHelpers";
import { addUsers } from "../redux/action";


export default function WaitingScreen() {
  const { min } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    // Handle events here
    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });
    socket.on("recieve_room_users", (data) => {
      console.log(data);
      dispatch(addUsers(data));
      navigate(`/room/${data[0].roomId}/${min}`);
    });
  }, [dispatch]);

  useEffect(() => {
    const _id = localStorage.getItem("userId");
    if (_id === undefined || _id === "" || _id === null) {
      navigate("/");
    }
  }, []);

  return (
    <div style={rootDiv}>
      <img
        src={require("./searching-opponent.gif")}
        alt="waiting"
        style={{ width: 200, height: 200 }}
      />
      <h3 style={{ color: "white" }}> Searching for opponent...</h3>
    </div>
  );
}

const rootDiv = {
  display: "flex",
  justifyContent: "center",
  height: "100vh",
  alignItems: "center",
  backgroundColor: "rgb(46, 46, 46)",
  flexDirection: "column",
};
