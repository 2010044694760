export const initialPieces = {
  "0:0": {
    image: require("../assets/wr.png"),
    color: "w",
    pieceName: "r",
    hasMoved: false,
  },
  "0:1": {
    image: require("../assets/wn.png"),
    color: "w",
    pieceName: "n",
    hasMoved: false,
  },
  "0:2": {
    image: require("../assets/wb.png"),
    color: "w",
    pieceName: "b",
    hasMoved: false,
  },
  "0:3": {
    image: require("../assets/wk.png"),
    color: "w",
    pieceName: "k",
    hasMoved: false,
  },
  "0:4": {
    image: require("../assets/wq.png"),
    color: "w",
    pieceName: "q",
    hasMoved: false,
  },
  "0:5": {
    image: require("../assets/wb.png"),
    color: "w",
    pieceName: "b",
    hasMoved: false,
  },
  "0:6": {
    image: require("../assets/wn.png"),
    color: "w",
    pieceName: "n",
    hasMoved: false,
  },
  "0:7": {
    image: require("../assets/wr.png"),
    color: "w",
    pieceName: "r",
    hasMoved: false,
  },

  "1:0": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:1": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:2": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:3": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:4": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:5": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:6": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:7": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },

  "7:0": {
    image: require("../assets/br.png"),
    color: "b",
    pieceName: "r",
    hasMoved: false,
  },
  "7:1": {
    image: require("../assets/bn.png"),
    color: "b",
    pieceName: "n",
    hasMoved: false,
  },
  "7:2": {
    image: require("../assets/bb.png"),
    color: "b",
    pieceName: "b",
    hasMoved: false,
  },
  "7:3": {
    image: require("../assets/bk.png"),
    color: "b",
    pieceName: "k",
    hasMoved: false,
  },
  "7:4": {
    image: require("../assets/bq.png"),
    color: "b",
    pieceName: "q",
    hasMoved: false,
  },
  "7:5": {
    image: require("../assets/bb.png"),
    color: "b",
    pieceName: "b",
    hasMoved: false,
  },
  "7:6": {
    image: require("../assets/bn.png"),
    color: "b",
    pieceName: "n",
    hasMoved: false,
  },
  "7:7": {
    image: require("../assets/br.png"),
    color: "b",
    pieceName: "r",
    hasMoved: false,
  },

  "6:0": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:1": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:2": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:3": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:4": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:5": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:6": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:7": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
};

export const initialPiecesOpponent = {
  "0:0": {
    image: require("../assets/br.png"),
    color: "b",
    pieceName: "r",
    hasMoved: false,
  },
  "0:1": {
    image: require("../assets/bn.png"),
    color: "b",
    pieceName: "n",
    hasMoved: false,
  },
  "0:2": {
    image: require("../assets/bb.png"),
    color: "b",
    pieceName: "b",
    hasMoved: false,
  },
  "0:3": {
    image: require("../assets/bq.png"),
    color: "b",
    pieceName: "q",
    hasMoved: false,
  },
  "0:4": {
    image: require("../assets/bk.png"),
    color: "b",
    pieceName: "k",
    hasMoved: false,
  },
  "0:5": {
    image: require("../assets/bb.png"),
    color: "b",
    pieceName: "b",
    hasMoved: false,
  },
  "0:6": {
    image: require("../assets/bn.png"),
    color: "b",
    pieceName: "n",
    hasMoved: false,
  },
  "0:7": {
    image: require("../assets/br.png"),
    color: "b",
    pieceName: "r",
    hasMoved: false,
  },

  "1:0": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:1": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:2": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:3": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:4": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:5": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:6": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:7": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },

  "7:0": {
    image: require("../assets/wr.png"),
    color: "w",
    pieceName: "r",
    hasMoved: false,
  },
  "7:1": {
    image: require("../assets/wn.png"),
    color: "w",
    pieceName: "n",
    hasMoved: false,
  },
  "7:2": {
    image: require("../assets/wb.png"),
    color: "w",
    pieceName: "b",
    hasMoved: false,
  },
  "7:3": {
    image: require("../assets/wq.png"),
    color: "w",
    pieceName: "q",
    hasMoved: false,
  },
  "7:4": {
    image: require("../assets/wk.png"),
    color: "w",
    pieceName: "k",
    hasMoved: false,
  },
  "7:5": {
    image: require("../assets/wb.png"),
    color: "w",
    pieceName: "b",
    hasMoved: false,
  },
  "7:6": {
    image: require("../assets/wn.png"),
    color: "w",
    pieceName: "n",
    hasMoved: false,
  },
  "7:7": {
    image: require("../assets/wr.png"),
    color: "w",
    pieceName: "r",
    hasMoved: false,
  },

  "6:0": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:1": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:2": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:3": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:4": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:5": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:6": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:7": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
};

export const gridConstants = {
  gridSize: 430,
};

export const h = [1, 2, 3, 4, 5, 6, 7, 8];
export const v = ["a", "b", "c", "d", "e", "f", "g", "h"];

export const pawnReachedOtherSidePieces = [
  {
    image: require("../assets/br.png"),
    color: "b",
    pieceName: "r",
    hasMoved: false,
  },
  {
    image: require("../assets/bn.png"),
    color: "b",
    pieceName: "n",
    hasMoved: false,
  },
  {
    image: require("../assets/bb.png"),
    color: "b",
    pieceName: "b",
    hasMoved: false,
  },
  {
    image: require("../assets/bq.png"),
    color: "b",
    pieceName: "q",
    hasMoved: false,
  },
];

export const opponentPawnReachedOtherSidePieces = [
  {
    image: require("../assets/wr.png"),
    color: "w",
    pieceName: "r",
    hasMoved: false,
  },
  {
    image: require("../assets/wn.png"),
    color: "w",
    pieceName: "n",
    hasMoved: false,
  },
  {
    image: require("../assets/wb.png"),
    color: "w",
    pieceName: "b",
    hasMoved: false,
  },
  {
    image: require("../assets/wq.png"),
    color: "w",
    pieceName: "q",
    hasMoved: false,
  },
];

export const tempInitialPieces = {
  "0:0": {
    image: require("../assets/wr.png"),
    color: "w",
    pieceName: "r",
    hasMoved: false,
  },
  "0:1": {
    image: require("../assets/wn.png"),
    color: "w",
    pieceName: "n",
    hasMoved: false,
  },
  "0:2": {
    image: require("../assets/wb.png"),
    color: "w",
    pieceName: "b",
    hasMoved: false,
  },
  "0:3": {
    image: require("../assets/wk.png"),
    color: "w",
    pieceName: "k",
    hasMoved: false,
  },
  "0:4": {
    image: require("../assets/wq.png"),
    color: "w",
    pieceName: "q",
    hasMoved: false,
  },
  "0:5": {
    image: require("../assets/wb.png"),
    color: "w",
    pieceName: "b",
    hasMoved: false,
  },
  "0:6": {
    image: require("../assets/wn.png"),
    color: "w",
    pieceName: "n",
    hasMoved: false,
  },
  "0:7": {
    image: require("../assets/wr.png"),
    color: "w",
    pieceName: "r",
    hasMoved: false,
  },

  "1:0": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:1": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:2": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:3": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:4": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:5": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:6": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "1:7": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },

  "7:0": {
    image: require("../assets/br.png"),
    color: "b",
    pieceName: "r",
    hasMoved: false,
  },
  "7:1": {
    image: require("../assets/bn.png"),
    color: "b",
    pieceName: "n",
    hasMoved: false,
  },
  "7:2": {
    image: require("../assets/bb.png"),
    color: "b",
    pieceName: "b",
    hasMoved: false,
  },
  "7:3": {
    image: require("../assets/bk.png"),
    color: "b",
    pieceName: "k",
    hasMoved: false,
  },
  "7:4": {
    image: require("../assets/bq.png"),
    color: "b",
    pieceName: "q",
    hasMoved: false,
  },
  "7:5": {
    image: require("../assets/bb.png"),
    color: "b",
    pieceName: "b",
    hasMoved: false,
  },
  "7:6": {
    image: require("../assets/bn.png"),
    color: "b",
    pieceName: "n",
    hasMoved: false,
  },
  "7:7": {
    image: require("../assets/br.png"),
    color: "b",
    pieceName: "r",
    hasMoved: false,
  },

  "6:0": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:1": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:2": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:3": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:4": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:5": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:6": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "6:7": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
};

export const tempInitialPiecesOpponent = {
  "0:0": {
    image: require("../assets/br.png"),
    color: "b",
    pieceName: "r",
    hasMoved: false,
  },
  "0:1": {
    image: require("../assets/bn.png"),
    color: "b",
    pieceName: "n",
    hasMoved: false,
  },
  "0:2": {
    image: require("../assets/bb.png"),
    color: "b",
    pieceName: "b",
    hasMoved: false,
  },
  "0:3": {
    image: require("../assets/bq.png"),
    color: "b",
    pieceName: "q",
    hasMoved: false,
  },
  "0:4": {
    image: require("../assets/bk.png"),
    color: "b",
    pieceName: "k",
    hasMoved: false,
  },
  "0:5": {
    image: require("../assets/bb.png"),
    color: "b",
    pieceName: "b",
    hasMoved: false,
  },
  "0:6": {
    image: require("../assets/bn.png"),
    color: "b",
    pieceName: "n",
    hasMoved: false,
  },
  "0:7": {
    image: require("../assets/br.png"),
    color: "b",
    pieceName: "r",
    hasMoved: false,
  },

  "1:0": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:1": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:2": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:3": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:4": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:5": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:6": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },
  "1:7": {
    image: require("../assets/bp.png"),
    color: "b",
    pieceName: "p",
    hasMoved: false,
  },

  "7:0": {
    image: require("../assets/wr.png"),
    color: "w",
    pieceName: "r",
    hasMoved: false,
  },
  "7:1": {
    image: require("../assets/wn.png"),
    color: "w",
    pieceName: "n",
    hasMoved: false,
  },
  "7:2": {
    image: require("../assets/wb.png"),
    color: "w",
    pieceName: "b",
    hasMoved: false,
  },
  "7:3": {
    image: require("../assets/wq.png"),
    color: "w",
    pieceName: "q",
    hasMoved: false,
  },
  "7:4": {
    image: require("../assets/wk.png"),
    color: "w",
    pieceName: "k",
    hasMoved: false,
  },
  "7:5": {
    image: require("../assets/wb.png"),
    color: "w",
    pieceName: "b",
    hasMoved: false,
  },
  "7:6": {
    image: require("../assets/wn.png"),
    color: "w",
    pieceName: "n",
    hasMoved: false,
  },
  "7:7": {
    image: require("../assets/wr.png"),
    color: "w",
    pieceName: "r",
    hasMoved: false,
  },

  "6:0": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:1": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:2": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:3": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:4": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:5": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:6": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
  "6:7": {
    image: require("../assets/wp.png"),
    color: "w",
    pieceName: "p",
    hasMoved: false,
  },
};
