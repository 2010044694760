import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

const RoundsMatches = () => {
    const { tid, round, rounds } = useParams();
    const [winners, setWinners] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [roundsData, setRoundsData] = useState([])
    const [tournament, setTournament] = useState([]);
    const [StartDate, setStartDate] = useState(null);
    const [EndDate, setEndDate] = useState(null);
    const [time, setTime] = useState(null);


    const getRounds = async () => {
        const result = await fetch("https://chessplus.in/api/get_rounds")
        const res = await result.json();
        if (res.status === 200) {
            setRoundsData(res.result)
        } else {
            setRoundsData(res.result);
        }
    }

    const getTournaments = async () => {
        const result = await fetch("https://chessplus.in/api/get_tournament");
        const response = await result.json();
        console.log(response);
        if (response.status === 200) {
            let abc = [];
            response.result.map((tnmnt) => {
                if (tnmnt._id === tid) {
                    abc.push(tnmnt)
                }
            })
            setTournament(abc);
        } else {
            alert(response.message);
        }
    }

    const getWinners = async () => {
        const result = await fetch("https://chessplus.in/api/get_all_winners");
        const response = await result.json();
        if (response.status === 200) {
            let allData = response.result.filter((item) => {
                console.log(item);
                if (item.tournamentId === tid) {
                    return item;
                }
            });
            setWinners(allData);
        } else {
            alert(response.message);
        }
    }

    const [allTeams, setAllTeams] = useState([]);
    const getTeams = async () => {
        // setViewAllTeams(true)
        // setSingleTournament(rowData)
        const result = await fetch("https://chessplus.in/api/get_all_team", {
            method: "POST",
            body: JSON.stringify({ tournamentId: tid }),
            headers: {
                "Content-Type": "application/json"
            }
        });
        const response = await result.json();
        if (response.status === 200) {
            let teams = [];
            response.result.map((item) => {
                if (round == 1 && item.result === undefined && item.status === "Pending") {
                    teams.push(item)
                } else if ((item.roundNo == 2 && item.roundNo == round) && item.result === "Win") {
                    teams.push(item)
                } else if ((item.roundNo == 3 && item.roundNo == round) && item.result === "Win") {
                    teams.push(item)
                } else if ((item.roundNo == 4 && item.roundNo == round) && item.result === "Win") {
                    teams.push(item)
                } else if ((item.roundNo == 5 && item.roundNo == round) && item.result === "Win") {
                    teams.push(item)
                } else if ((item.roundNo == 6 && item.roundNo == round) && item.result === "Win") {
                    teams.push(item)
                } else if ((item.roundNo == 7 && item.roundNo == round) && item.result === "Win") {
                    teams.push(item)
                } else if ((item.roundNo == 8 && item.roundNo == round) && item.result === "Win") {
                    teams.push(item)
                } else if ((item.roundNo == 9 && item.roundNo == round) && item.result === "Win") {
                    teams.push(item)
                } else if ((item.roundNo == 10 && item.roundNo == round) && item.result === "Win") {
                    teams.push(item)
                }
            })
            setAllTeams(teams);
        } else {
            alert(response.message);
        }
    }

    useEffect(() => {
        getTournaments();
        getRounds();
        getWinners();
        getTeams();
    }, []);

    // const [allTeams, setAllTeams] = useState([]);


    const hideCreateRoomDialog = () => {
        setCreateRoomDialog(false);
    }

    const [createRoomDialog, setCreateRoomDialog] = useState(false)

    const [selectedUser, setSelectedUser] = useState([]);

    const getSelected = (rowData) => {
        setCreateRoomDialog(true);
        setSelectedUser(rowData)
    }

    const createRoomButton = (rowData) => {
        if (rowData.result !== "Win") {
            if (rowData.result === undefined || rowData.result === "Win") {
                return <Button label="Create Room" severity="primary" onClick={() => getSelected(rowData)} />
            } else {
                return <Button label="Room Joined" severity="primary" />
            }
        } else {
            return <Button label="Room Joined" severity="primary" />
        }
    }

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Total Teams</h4>
            <h4 className="m-0 underline text-uppercase">{tournament[0]?.title}</h4>
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                />
            </IconField>
        </div>
    );

    const getOpponentUser = async (uId) => {
        if (!uId || !StartDate || !EndDate) {
            alert("Select Opponent First or Start Date or End Date");
            return
        } else {
            let opponentName = "";
            allTeams.map((items) => {
                if (uId === items.userId) {
                    opponentName = items.username
                }
            })
            const result = await fetch("https://chessplus.in/api/update_users", {
                method: "POST",
                body: JSON.stringify({
                    tournamentId: tid,
                    userId: selectedUser.userId,
                    userName: selectedUser.username,
                    opponentId: uId,
                    opponentName: opponentName,
                    StartDate: StartDate,
                    EndDate: EndDate,
                    roundNo: round
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const res = await result.json();
            if (res.status === 200) {
                alert(res.message);
                window.location.reload();
            } else {
                alert(res.message);
            }
        }
    }

    return (
        <div>
            <div className="card">
                <DataTable
                    ref={dt}
                    value={allTeams}
                    selection={allTeams}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Data"
                    // globalFilter={globalFilter}
                    header={header}
                >
                    <Column field="roomid" header="Room ID" sortable></Column>
                    {/* <Column field="tournamentTitle" header="Title" sortable></Column> */}
                    <Column field="userId" header="UserId" sortable></Column>
                    <Column header="UserName" field="username" sortable></Column>
                    <Column header="Action" body={createRoomButton}></Column>
                </DataTable>
            </div>
            <Dialog
                visible={createRoomDialog}
                style={{ width: "40rem" }}
                breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                header="Confirm"
                modal
                onHide={hideCreateRoomDialog}
            >
                <div className="row">
                    <div className="col-lg-12">
                        <label>Player Name</label>
                        <input type="text" className="form-control" value={selectedUser && selectedUser.username} readOnly />
                    </div>

                    <div className="col-lg-4 mt-2">
                        <label>Game Start Date</label>
                        <input
                            type="date"
                            className="form-control"
                            placeholder="Joining Start Date"
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-4 mt-2">
                        <label>Game End Date</label>
                        <input
                            type="date"
                            className="form-control"
                            placeholder="Joining End Date"
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-4 mt-2">
                        <label>Total Time</label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Game Total Time"
                            onChange={(e) => setTime(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-12 mt-2">
                        <label>Select Opponent</label>
                        <select className="form-select" onChange={(e) => getOpponentUser(e.target.value)} >
                            <option value={""}>Select One</option>
                            {
                                allTeams.map((items) => {
                                    if (selectedUser.userId !== items.userId && (allTeams.result === undefined || allTeams.result === "Win")) {
                                        return <option value={items.userId}>{items.username}</option>
                                    }
                                })
                            }
                        </select>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default RoundsMatches