import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
const Announcement = ({ tournamentId }) => {
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null)

  const [allTeams1, setAllTeams1] = useState([]);
  const getTeams1 = async () => {
    const result = await fetch("https://chessplus.in/api/get_all_team", {
      method: "POST",
      body: JSON.stringify({ tournamentId: tournamentId }),
      headers: {
        "Content-Type": "application/json"
      }
    });
    const response = await result.json();
    if (response.status === 200) {
      setAllTeams1(response.result);
    } else {
      alert(response.message);
    }
  }
  useEffect(() => {
    getTeams1();
  }, [])

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="d-flex announcementButton">
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => { }}
        />
        <Button
          label="View"
          rounded
          outlined
          severity="primary"
          style={{ marginLeft: 5 }}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">All Joined Teams</h4>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </IconField>
    </div>
  );


  return (
    <div className="AllTournament">
      <Toast ref={toast} />
      <div className="card">
        <DataTable
          ref={dt}
          value={allTeams1}
          selection={allTeams1}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Data"
          globalFilter={globalFilter}
          header={header}
        >
          {/* <Column field="roomid" header="Room ID" sortable></Column> */}
          <Column field="tournamentTitle" style={{fontSize:13}} header="Title" sortable></Column>
          <Column field="userId" style={{fontSize:13}} header="UserId" sortable></Column>
          <Column header="UserName" style={{fontSize:13}} field="username" sortable></Column>
          {/* <Column header="Action" body={actionBodyTemplate}></Column> */}
        </DataTable>
      </div>
    </div>
  );
};
export default Announcement;
