import React, { useEffect, useState } from "react";

const Captcha = ({ onValidate, isCaptchaValid }) => {
  const [input, setInput] = useState("");
  const [captcha, setCaptcha] = useState("");

  useEffect(() => {
    generateCaptcha();
  }, []);
  const generateCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setCaptcha(result);
  };

  const handleInputChange = (e) => { };

  const handleSubmit = (value) => {
    if (value.length > 5 && value.length < 7) {
      if (value === captcha) {
        setInput(value);
        onValidate(true);
      } else {
        onValidate(false);
      }
      generateCaptcha(); // Generate a new CAPTCHA on each submission
      setInput("");
    }
  };

  return (
    <div className="captchaDiv">
      {
        isCaptchaValid ?
          (<div className="d-flex align-items-center justify-content-between">
            <label className="form-control-label fw-bold">CAPTCHA</label>
            <div className="captchaText ms-auto"></div>
          </div>) :
          (<div className="d-flex align-items-center justify-content-between">
            <label className="form-control-label fw-bold">CAPTCHA</label>
            <div className="captchaText ms-auto">{captcha}</div>
          </div>)
      }

      <input
        type="text"
        className="form-control"
        defaultValue={input}
        onChange={(e) => handleSubmit(e.target.value)}
        required
        placeholder="Enter captcha here.."
      />
    </div>
  );
};

export default Captcha;
