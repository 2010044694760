import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { url } from "../../helpers/apiHelpers";

const Sidebar = () => {
  const navigate = useNavigate();
  const [toggleB, setToggleB] = useState(false);
  const sidebarToggle = () => {
    if (toggleB === false) {
      setToggleB(true);
    } else {
      setToggleB(false);
    }
  };
  const adminId = localStorage.getItem("adminId");
  const adminType = localStorage.getItem("adminType");
  const adminName = localStorage.getItem("adminName");
  useEffect(() => {
    if (!adminId && adminType !== "Admin") {
      navigate("/admin-login");
    }
  }, []);

  const pathName = window.location.pathname;
  return (
    <>
      <div className={`sidebar ${toggleB === true ? "close" : ""}`}>
        <div className="logo-details px-3 py-4">
          <img src={`${url}/logo.jpg`} alt="" />
        </div>
        <ul className="nav-links">
          <li className={pathName === "/admin" ? "active" : ""}>
            <Link to="/admin">
              <i className="bx bx-grid-alt"></i>
              <span className="link_name">Dashboard</span>
            </Link>
          </li>

          <li
            className={pathName === "/admin/all-tournaments" ? "active" : ""}
          >
            <Link to={"/admin/all-tournaments"}>
              <i class="fa fa-bullhorn" aria-hidden="true"></i>
              <span className="link_name">All Tournaments</span>
            </Link>
          </li>
          <li
            className={pathName === "/admin/all-winners" ? "active" : ""}
          >
            <Link to="/admin/all-winners">
              <i className="bx bx-line-chart"></i>
              <span className="link_name">All Winners</span>
            </Link>
          </li>
          <li
            className={pathName === "/admin/all-packages" ? "active" : ""}
          >
            <Link to="/admin/all-packages">
              <i className="bx bx-line-chart"></i>
              <span className="link_name">All Packages</span>
            </Link>
          </li>
          <li
            className={pathName === "/admin/all-transaction" ? "active" : ""}
          >
            <Link to="/admin/all-transaction">
              <i className="fa fa-rupee-sign"></i>
              <span className="link_name">All Transactions</span>
            </Link>
          </li>
          <li>
            <div className="iocn-link">
              <Link to="#">
                <i className="bx bx-plug"></i>
                <span className="link_name">Plugins</span>
              </Link>
              <i className="bx bxs-chevron-down arrow"></i>
            </div>
            <ul className="sub-menu">
              <li>
                <Link className="link_name" to="#">
                  Plugins
                </Link>
              </li>
              <li>
                <Link to="#">UI Face</Link>
              </li>
              <li>
                <Link to="#">Pigments</Link>
              </li>
              <li>
                <Link to="#">Box Icons</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <section className="home-section">
        <div className="home-content bg-dark d-flex justify-content-between align-items-center">
          <button
            className="ms-4 btn btn-outline-info btn-sm"
            onClick={() => sidebarToggle()}
          >
            <i className="bx bx-menu text-white"></i>
          </button>
          <h4 className="text-white me-5">{adminName}</h4>
        </div>
        <div className="card p-3 m-3">
          <Outlet />
        </div>
      </section>
    </>
  );
};

export default Sidebar;
