import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { socket } from "../helpers/apiHelpers";

const AllJoinedTeam = () => {
    const [myTeams, setMyTeams] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);


    const _id = localStorage.getItem("userId");
    const getTeams = async () => {
        const result = await fetch("https://chessplus.in/api/get_join_team", {
            method: "POST",
            body: JSON.stringify({ userId: _id }),
            headers: {
                "Content-Type": "application/json"
            }
        });
        const response = await result.json();

        if (response.status === 200) {
            setMyTeams(response.result);
        } else {
            alert(response.message);
        }
    }
    useEffect(() => {
        getTeams();
    }, []);

    const joinChessRoom = (tId, min, roomid, singleRoomId, tournamentId, roundNo) => {
        socket.emit("join_room", {
            tId: tId,
            userId: user?._id,
            username: user?.username,
            roomid: roomid,
            singleRoomId: singleRoomId,
            tournamentId: tournamentId,
            roundNo: roundNo
        });

        navigate(`/waiting/${min}`);
    };
    return (
        <div>
            <div className="row">
                <div className="col-lg-12 col-12 mb-3">
                    <h3>All Joined Teams</h3>
                    <hr className="my-0" />
                </div>
                {myTeams &&
                    myTeams.map((item) => {
                        return (
                            <div className="col-lg-4 col-12 col-md-6" key={item._id}>
                                <div className="card rounded-2 bg-dark" >
                                    <div className="card-body p-3">
                                        <p className="text-white mb-1 fw-bold">Round No. :- {item.roundNo}</p>
                                        <p className="text-white mb-1">Tournament ID :- {item.tournamentId}</p>
                                        <p className="mb-1 text-white">Title :- <span className="fw-bold">{item.tournamentTitle}</span></p>
                                        <p className="mb-1 text-white"> Room Id :- {item.roomid}</p>
                                        <p className="mb-1 text-white"> Play Time :- {item.timeControl}</p>
                                        <p className="mb-1 text-white">
                                            Date :- <label>{item.startDate}</label> - <label>{item.endDate}</label>
                                        </p>
                                        {
                                            item.status === "Done" ?
                                                <h5 className="text-success fw-bold">Match Done </h5> :
                                                item.roomid === undefined || item.roomid === "" ? (
                                                    ""
                                                ) : (
                                                    <div className="text-center">
                                                        <button type="button" onClick={() => {
                                                            joinChessRoom(item.tournamentId, item.timeControl, item.roomid, item.singleRoomId, item.tournamentId, item.roundNo);
                                                        }} className="btn btn-danger">Play</button>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    )
}

export default AllJoinedTeam