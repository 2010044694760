import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import moment from "moment";
const AllTransaction = () => {
    const [PayDetailsDialog, PayDetailsersDialog] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const [payments, setPayments] = useState(null);
    const getPayments = async () => {
        const result = await fetch("https://chessplus.in/api/payment_details");
        const response = await result.json();
        if (response.status === 200) {
            setPayments(response.result);
        } else {
            alert(response.message);
        }
    };

    useEffect(() => {
        getPayments();

    }, []);

    const openNew = () => {
        PayDetailsersDialog(true);
    };

    const hideDialog = () => {
        PayDetailsersDialog(false);
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-flex announcementButton">
                <Button
                    icon="pi pi-eye"
                    outlined
                    severity="primary"
                    className="ms-2"
                    onClick={() => { setSelectedData(rowData); openNew() }}
                />
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between overflow-scroll pb-3">
            <h4 className="m-0">Payments Details </h4>
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                />
            </IconField>
        </div>
    );
    const PayDetailsDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
        </React.Fragment>
    );
    const payBy = (rowData) => {
        if (rowData.mode === 'NB') {
            return <small style={{ textWrap: "noWrap" }}>Net Banking</small>
        } else if (rowData.mode === 'CC') {
            return <small style={{ textWrap: "noWrap" }}>Credit Card</small>
        } else if (rowData.mode === 'DC') {
            return <small style={{ textWrap: "noWrap" }}>Debit Card</small>
        } else if (rowData.mode === 'CASH') {
            return <small style={{ textWrap: "noWrap" }}>Wallets</small>
        } else {
            return <small style={{ textWrap: "noWrap" }}>{rowData.mode}</small>
        }
    }
    const DateChange = (rowData) => {
        let date = rowData.addedon;
        return moment(date).format("D-MM-Y h:m A")
    }

    return (
        <div className="AllWinners">
            <Toast ref={toast} />
            <div className="card">
                <DataTable
                    ref={dt}
                    value={payments}
                    selection={selectedProducts}
                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Data"
                    globalFilter={globalFilter}
                    header={header}
                >
                    <Column field="addedon" header="Date" body={DateChange} sortable></Column>
                    <Column field="txnid" header="Txn Id" sortable></Column>
                    <Column field="firstname" header="Name" sortable></Column>
                    <Column field="amount" header="Amount" bodyStyle={{ fontWeight: "bold" }} sortable></Column>
                    <Column field="productTitle" header="Tournaments" bodyStyle={{ color: 'red' }} sortable></Column>
                    <Column field="mode" header="PayBy" body={payBy} sortable></Column>
                    <Column field="field9" header="Notes" bodyStyle={{ color: 'green' }} sortable></Column>
                    <Column field="status" header="status" bodyStyle={{ color: 'green', fontWeight: "bold" }} sortable></Column>
                    <Column body={actionBodyTemplate} exportable={false}></Column>

                </DataTable>
            </div>


            <Dialog
                visible={PayDetailsDialog}
                style={{ width: "50rem" }}
                breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                header="Payment Details"
                modal
                className="p-fluid PaymentComponent"
                footer={PayDetailsDialogFooter}
                onHide={hideDialog}
            >
                <table className="table table-bordered ">
                    <tr>
                        <th>Name:</th><td>{selectedData?.firstname}</td>
                        <th>Phone:</th><td>{selectedData?.phone}</td>
                    </tr>
                    <tr>
                        <th>Email:</th><td>{selectedData?.email}</td>
                        <th>ProductID:</th><td>{selectedData?.productinfo}</td>
                    </tr>
                    <tr>
                        <th>ProductTitle:</th><td>{selectedData?.productTitle}</td>
                        <th>Amount:</th><td>{selectedData?.amount}</td>
                    </tr>
                    <tr>
                        <th>Status:</th><td>{selectedData?.status}</td>
                        <th>Addedon:</th><td>{selectedData?.addedon}</td>
                    </tr>
                    <tr>
                        <th>Txn Id:</th><td>{selectedData?.txnid}</td>
                        <th>Bank Code:</th><td>{selectedData?.bankcode}</td>
                    </tr>
                    <tr>
                        <th>Bank Ref No.:</th><td>{selectedData?.bank_ref_num}</td>
                        <th>Card No.:</th><td>{selectedData?.cardnum}</td>
                    </tr>
                    <tr>
                        <th>Notes:</th><td colSpan={3}>{selectedData?.field9}</td>
                    </tr>

                </table>

            </Dialog>

        </div>
    );
};
export default AllTransaction