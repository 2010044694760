import React, { useEffect, useState } from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../redux/action";
import { url } from "../helpers/apiHelpers";

function Main() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const _id = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");

  const user = useSelector((state) => state.user.user);

  const [toggleB, setToggleB] = useState(false);
  const sidebarToggle = () => {
    if (toggleB === false) {
      setToggleB(true);
    } else {
      setToggleB(false);
    }
  };
  const [userData, setUserData] = useState("");
  const getUserData = async () => {
    let result = await fetch("https://chessplus.in/api/user_details", {
      method: "POST",
      body: JSON.stringify({ userId: _id }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let res = await result.json();
    if (res.status === 200) {
      dispatch(addUser(res.user));
      setUserData(res.user);
    } else {
      alert(res.message);
    }
  };
  const pathName = window.location.pathname;

  useEffect(() => {
    getUserData();
    if (_id === undefined || _id === "" || _id === null) {
      navigate("/");
      dispatch(addUser(""));
    }
  }, []);

  return (
    <div id="wrapper" className={` ${toggleB === true ? "toggled" : ""}`}>
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand">
          <img src={`${url}/logo.jpg`} className="h-8 " width={120} alt="..." />
        </div>
        <ul className="sidebar-nav">
          <li className={pathName === "/users/home" ? "active" : ""}>
            <Link to={"/users/home"}>
              <i className="fa fa-home"></i>Dashboard
            </Link>
          </li>
          <li className={pathName === "https://chessplus.in/withcomputer/index.html" ? "active" : ""}>
            <a href={"https://chessplus.in/withcomputer/index.html"}>
              <i className="fa fa-home"></i>Play With Computer
            </a>
          </li>

          <li className={pathName === "/users/tournaments" ? "active" : ""}>
            <Link to={"/users/tournaments"}>
              <i className="fa fa-chess"></i>Tournaments
            </Link>
          </li>
          <li className={pathName === "/users/join-tournaments" ? "active" : ""}>
            <Link to={"/users/join-tournaments"}>
              <i className="fa fa-chess"></i>Join Tournaments
            </Link>
          </li>
          <li className={pathName === "/users/winning-games" ? "active" : ""}>
            <Link to={"/users/winning-games"}>
              <i className="fa fa-trophy"></i>Winning Games
            </Link>
          </li>
          <li className={pathName === "/users/payment-details" ? "active" : ""}>
            <Link to={"/users/payment-details"}>
              <i className="fa fa-rupee-sign"></i>Payment Details
            </Link>
          </li>
          <li className={pathName === "/users/profile" ? "active" : ""}>
            <Link to={"/users/profile"}>
              <i className="fa fa-user"></i>Profile
            </Link>
          </li>
          <li className={pathName === "/logout" ? "active" : ""}>
            <Link to={`/logout/${userType}`}>
              <i className="fa fa-sign-out"></i>Logout
            </Link>
          </li>
        </ul>
      </aside>

      <div id="navbar-wrapper">
        <nav className="navbar navbar-inverse">
          <div className="container-fluid">
            <div className="navbar-header">
              <Link
                to="#"
                className="navbar-brand"
                id="sidebar-toggle"
                onClick={() => sidebarToggle()}
              >
                <i className="fa fa-bars text-white"></i>
              </Link>
            </div>
            <ul className="userName">
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  aria-expanded="false"
                >
                  <i className="fa fa-user"> </i> {userData.name}
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to={`/logout/${userType}`}>
                      Logout
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <section id="content-wrapper">
        <div className="row">
          <div className="col-lg-12">
            <div className="card p-3">
              {
                pathName === "/users/play-with-computer" ? (
                  <div className="row">
                    <div className="col-lg-12 m-auto withComputer">
                      <Outlet />
                    </div>
                  </div>
                ) : (
                  <Outlet />
                )
              }
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Main;
