import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../../helpers/apiHelpers";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");


  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const getLogin = async () => {
    if (!username || !password) {
      alert("Fill all Mandatory Fields");
      return;
    } else {
      const result = await fetch("https://chessplus.in/api/admin_login", {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      let res = await result.json();
      if (res.status === 200) {
        localStorage.setItem("adminId", res.user._id);
        localStorage.setItem("adminType", "Admin");
        localStorage.setItem("adminName", res.user.name);
        navigate("/admin");
      } else {
        alert(res.message);
      }
    }
  };

  return (
    <div
      className="w-100 admin-Login-bg "
      style={{
        backgroundImage: `url(${url}/img/bg-image.jpg)`,
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <div className="container px-0 p-lg-5">
        <div className="container position-relative">
          <div className="row m-5 no-gutters shadow-lg">
            <div className="col-md-6 px-0 d-none d-md-block">
              <img
                src={`${url}/img/chess2.jpg`}
                className="img-fluid"
                style={{ minHeight: "100%" }}
              />
            </div>
            <div className="col-md-6 bg-white p-5">
              <h3 className="pb-3">Admin Login</h3>
              <div className="form-style">
                <div className="form-group pb-3">
                  <input
                    type="text"
                    placeholder="Email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="form-group pb-3 position-relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    className="form-control"
                    id="exampleInputPassword1"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Link onClick={togglePasswordVisibility} className="adminEyeIcon">
                    {showPassword ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
                  </Link>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <input name="" type="checkbox" value="" />{" "}
                    <span className="pl-2 font-weight-bold">Remember Me</span>
                  </div>
                </div>
                <div className="pb-2">
                  <button
                    type="button"
                    className="btn btn-dark w-100 font-weight-bold mt-2"
                    onClick={() => getLogin()}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
