// exports.callingOpponentForCheckMate = (
//   kingPosX,
//   kingPosY,
//   x,
//   y,
//   pieceName,
//   pieces
// ) => {
//   let checkMateAttack = new CheckMateAttack(kingPosX, kingPosY, x, y, pieces);

//   console.log(kingPosX, kingPosY, x, y, pieceName);

//   if (pieceName === "p") {
//     return checkMateAttack.isPawn();
//   }

//   if (pieceName === "r") {
//     return checkMateAttack.isRook();
//   }

//   if (pieceName === "b") {
//     return checkMateAttack.isBishop();
//   }

//   if (pieceName === "n") {
//     return checkMateAttack.isKnight();
//   }

//   if (pieceName === "q") {
//     return checkMateAttack.isQueen();
//   }

//   if (pieceName === "k") {
//     return checkMateAttack.isKing();
//   }
// };

// class CheckMateAttack {
//   constructor(kingPosX, kingPosY, x, y, pieces) {
//     this.x = x;
//     this.y = y;
//     this.pieces = pieces;

//     this.kingPosX = kingPosX;
//     this.kingPosY = kingPosY;
//   }

//   isPawn() {
//     if (this.kingPosX - 1 !== this.x || Math.abs(this.kingPosY - this.y) > 1)
//       return false;

//     let kingPos = this.kingPosX.toString() + ":" + this.kingPosY.toString();

//     let pos = this.x.toString() + ":" + this.y.toString();

//     if (
//       this.pieces[pos] !== undefined &&
//       this.pieces[pos].color !== this.pieces[kingPos].color
//     ) {
//       return true;
//     }

//     return this.pieces[pos] === undefined &&
//       Math.abs(this.kingPosY - this.y) === 0
//       ? true
//       : false;
//   }

//   isRook() {
//     if (Math.abs(this.kingPosX - this.x) && Math.abs(this.kingPosY - this.y))
//       return false;

//     let currentPiecePos = this.x.toString() + ":" + this.y.toString();

//     let currentPieceColor = this.pieces[currentPiecePos].color;

//     //right
//     for (let i = this.y + 1; i <= this.kingPosY; i++) {
//       let pos = this.x.toString() + ":" + i.toString();

//       if (this.pieces[pos] && this.pieces[pos].color === currentPieceColor)
//         break;

//       if (this.pieces[pos] && this.pieces[pos].color !== currentPieceColor) {
//         if (this.x === this.kingPosX && i === this.kingPosY) {
//           console.log("right");
//           return true;
//         } else break;
//       }
//     }

//     //left
//     for (let i = this.y - 1; i >= this.kingPosY; i--) {
//       let pos = this.x.toString() + ":" + i.toString();

//       if (this.pieces[pos] && this.pieces[pos].color === currentPieceColor)
//         break;

//       if (this.pieces[pos] && this.pieces[pos].color !== currentPieceColor) {
//         if (this.x === this.kingPosX && i === this.kingPosY) {
//           console.log("left");
//           return true;
//         } else break;
//       }
//     }

//     //up
//     for (let i = this.x - 1; i >= this.kingPosX; i--) {
//       let pos = i.toString() + ":" + this.y.toString();

//       if (this.pieces[pos] && this.pieces[pos].color === currentPieceColor)
//         break;

//       if (this.pieces[pos] && this.pieces[pos].color !== currentPieceColor) {
//         if (i === this.kingPosX && this.y === this.kingPosY) {
//           console.log("up");
//           return true;
//         } else break;
//       }
//     }

//     //down
//     for (let i = this.x + 1; i <= this.kingPosX; i++) {
//       let pos = i.toString() + ":" + this.y.toString();

//       if (this.pieces[pos] && this.pieces[pos].color === currentPieceColor)
//         break;

//       if (this.pieces[pos] && this.pieces[pos].color !== currentPieceColor) {
//         if (i === this.kingPosX && this.y === this.kingPosY) {
//           console.log("down");
//           return true;
//         } else break;
//       }
//     }
//     return false;
//   }

//   isBishop() {
//     if (Math.abs(this.kingPosX - this.x) !== Math.abs(this.kingPosY - this.y)) {
//       return false;
//     }

//     let currentPiecePos = this.x.toString() + ":" + this.y.toString();

//     let currentPieceColor = this.pieces[currentPiecePos].color;

//     //leftUp

//     for (
//       let i = this.x - 1, j = this.y - 1;
//       i >= this.kingPosX && j >= this.kingPosY;
//       i--, j--
//     ) {
//       let pos = i.toString() + ":" + j.toString();

//       if (this.pieces[pos] && this.pieces[pos].color === currentPieceColor) {
//         break;
//       }

//       if (this.pieces[pos] && this.pieces[pos].color !== currentPieceColor) {
//         if (i === this.kingPosX && j === this.kingPosY) {
//           console.log("leftup");
//           return true;
//         } else break;
//       }
//     }

//     //leftDown

//     for (
//       let i = this.x + 1, j = this.y - 1;
//       i <= this.kingPosX && j >= this.kingPosY;
//       i++, j--
//     ) {
//       let pos = i.toString() + ":" + j.toString();

//       if (this.pieces[pos] && this.pieces[pos].color === currentPieceColor)
//         break;

//       if (this.pieces[pos] && this.pieces[pos].color !== currentPieceColor) {
//         if (i === this.kingPosX && j === this.kingPosY) {
//           console.log("leftdown");
//           return true;
//         } else break;
//       }
//     }

//     //rightUp

//     for (
//       let i = this.x - 1, j = this.y + 1;
//       i >= this.kingPosX && j <= this.kingPosY;
//       i--, j++
//     ) {
//       let pos = i.toString() + ":" + j.toString();

//       if (this.pieces[pos] && this.pieces[pos].color === currentPieceColor)
//         break;

//       if (this.pieces[pos] && this.pieces[pos].color !== currentPieceColor) {
//         if (i === this.kingPosX && j === this.kingPosY) {
//           console.log("rightup");
//           return true;
//         } else break;
//       }
//     }

//     //rightDown

//     for (
//       let i = this.x + 1, j = this.y + 1;
//       i <= this.kingPosX && j <= this.kingPosY;
//       i++, j++
//     ) {
//       let pos = i.toString() + ":" + j.toString();

//       if (this.pieces[pos] && this.pieces[pos].color === currentPieceColor)
//         break;

//       if (
//         this.pieces[pos] &&
//         this.pieces[pos].color !== currentPieceColor &&
//         i === this.kingPosX &&
//         j === this.kingPosY
//       ) {
//         if (i === this.kingPosX && j === this.kingPosY) {
//           console.log("rightdown");
//           return true;
//         } else break;
//       }
//     }
//     return false;
//   }

//   isKnight() {
//     if (
//       (this.kingPosX + 2 === this.x && this.kingPosY + 1 === this.y) ||
//       (this.kingPosX - 2 === this.x && this.kingPosY + 1 === this.y) ||
//       (this.kingPosX + 2 === this.x && this.kingPosY - 1 === this.y) ||
//       (this.kingPosX - 2 === this.x && this.kingPosY - 1 === this.y) ||
//       (this.kingPosX + 1 === this.x && this.kingPosY + 2 === this.y) ||
//       (this.kingPosX - 1 === this.x && this.kingPosY + 2 === this.y) ||
//       (this.kingPosX + 1 === this.x && this.kingPosY - 2 === this.y) ||
//       (this.kingPosX - 1 === this.x && this.kingPosY - 2 === this.y)
//     ) {
//       return true;
//     }

//     return false;
//   }

//   isQueen() {
//     return this.isBishop() || this.isRook();
//   }

//   isKing() {
//     if (
//       Math.abs(this.kingPosX - this.x) <= 1 &&
//       Math.abs(this.kingPosY - this.y) <= 1
//     )
//       return true;

//     return false;
//   }
// }


exports.callingOpponentForCheckMate = (kingPosX, kingPosY, x, y, pieceName, pieces) => {
  let checkMateAttack = new CheckMateAttack(kingPosX, kingPosY, x, y, pieces);
  switch (pieceName) {
    case "p":
      return checkMateAttack.isPawn();
    case "r":
      return checkMateAttack.isRook();
    case "b":
      return checkMateAttack.isBishop();
    case "n":
      return checkMateAttack.isKnight();
    case "q":
      return checkMateAttack.isQueen();
    case "k":
      return checkMateAttack.isKing();
    default:
      return false;
  }
};

class CheckMateAttack {
  constructor(kingPosX, kingPosY, x, y, pieces) {
    this.x = x;
    this.y = y;
    this.pieces = pieces;
    this.kingPosX = kingPosX;
    this.kingPosY = kingPosY;
  }

  isPawn() {
    return (this.kingPosX - 1 === this.x && Math.abs(this.kingPosY - this.y) <= 1) && this.isDifferentColor();
  }

  isRook() {
    return this.isLinearMoveValid();
  }

  isBishop() {
    return this.isDiagonalMoveValid();
  }

  isKnight() {
    const knightMoves = [
      [2, 1], [2, -1], [-2, 1], [-2, -1],
      [1, 2], [1, -2], [-1, 2], [-1, -2]
    ];
    return knightMoves.some(move => this.kingPosX + move[0] === this.x && this.kingPosY + move[1] === this.y);
  }

  isQueen() {
    return this.isLinearMoveValid() || this.isDiagonalMoveValid();
  }

  isKing() {
    return Math.abs(this.kingPosX - this.x) <= 1 && Math.abs(this.kingPosY - this.y) <= 1;
  }

  isDifferentColor() {
    let kingPos = `${this.kingPosX}:${this.kingPosY}`;
    let pos = `${this.x}:${this.y}`;
    return this.pieces[pos] !== undefined && this.pieces[pos].color !== this.pieces[kingPos].color;
  }

  isLinearMoveValid() {
    if (Math.abs(this.kingPosX - this.x) && Math.abs(this.kingPosY - this.y)) return false;
    let currentPiecePos = `${this.x}:${this.y}`;
    let currentPieceColor = this.pieces[currentPiecePos].color;

    // Check right, left, up, and down directions
    return this.isDirectionClear(currentPieceColor, 0, 1) ||
      this.isDirectionClear(currentPieceColor, 0, -1) ||
      this.isDirectionClear(currentPieceColor, 1, 0) ||
      this.isDirectionClear(currentPieceColor, -1, 0);
  }

  isDiagonalMoveValid() {
    if (Math.abs(this.kingPosX - this.x) !== Math.abs(this.kingPosY - this.y)) return false;
    let currentPiecePos = `${this.x}:${this.y}`;
    let currentPieceColor = this.pieces[currentPiecePos].color;

    // Check diagonal directions
    return this.isDirectionClear(currentPieceColor, 1, 1) ||
      this.isDirectionClear(currentPieceColor, 1, -1) ||
      this.isDirectionClear(currentPieceColor, -1, 1) ||
      this.isDirectionClear(currentPieceColor, -1, -1);
  }

  isDirectionClear(currentPieceColor, dx, dy) {
    for (let i = 1; i < 8; i++) {
      let nextX = this.x + i * dx;
      let nextY = this.y + i * dy;
      let pos = `${nextX}:${nextY}`;
      if (!this.pieces[pos]) continue;
      if (this.pieces[pos].color === currentPieceColor) break;
      if (this.pieces[pos].color !== currentPieceColor) return pos === `${this.kingPosX}:${this.kingPosY}`;
    }
    return false;
  }
}

// exports.callingOpponentForCheckMate = (kingPosX, kingPosY, x, y, pieceName, pieces) => {
//   let checkMateAttack = new CheckMateAttack(kingPosX, kingPosY, x, y, pieces);
//   switch (pieceName) {
//     case "p":
//       return checkMateAttack.isPawn();
//     case "r":
//       return checkMateAttack.isRook();
//     case "b":
//       return checkMateAttack.isBishop();
//     case "n":
//       return checkMateAttack.isKnight();
//     case "q":
//       return checkMateAttack.isQueen();
//     case "k":
//       return checkMateAttack.isKing();
//     default:
//       return false;
//   }
// };

// class CheckMateAttack {
//   constructor(kingPosX, kingPosY, x, y, pieces) {
//     this.kingPosX = kingPosX;
//     this.kingPosY = kingPosY;
//     this.x = x;
//     this.y = y;
//     this.pieces = pieces;
//   }

//   isPawn() {
//     return (this.kingPosX - 1 === this.x && Math.abs(this.kingPosY - this.y) <= 1) && this.isDifferentColor();
//   }

//   isRook() {
//     return this.isLinearMoveValid();
//   }

//   isBishop() {
//     return this.isDiagonalMoveValid();
//   }

//   isKnight() {
//     const knightMoves = [
//       [2, 1], [2, -1], [-2, 1], [-2, -1],
//       [1, 2], [1, -2], [-1, 2], [-1, -2]
//     ];
//     return knightMoves.some(move => this.kingPosX + move[0] === this.x && this.kingPosY + move[1] === this.y && this.isDifferentColor());
//   }

//   isQueen() {
//     return this.isLinearMoveValid() || this.isDiagonalMoveValid();
//   }

//   isKing() {
//     return Math.abs(this.kingPosX - this.x) <= 1 && Math.abs(this.kingPosY - this.y) <= 1 && this.isDifferentColor();
//   }

//   isDifferentColor() {
//     let kingPos = `${this.kingPosX}:${this.kingPosY}`;
//     let pos = `${this.x}:${this.y}`;
//     return this.pieces[pos] !== undefined && this.pieces[kingPos] !== undefined && this.pieces[pos].color !== this.pieces[kingPos].color;
//   }

//   isLinearMoveValid() {
//     if (this.kingPosX !== this.x && this.kingPosY !== this.y) return false;

//     let dx = this.kingPosX === this.x ? 0 : (this.kingPosX > this.x ? -1 : 1);
//     let dy = this.kingPosY === this.y ? 0 : (this.kingPosY > this.y ? -1 : 1);

//     let i = this.x + dx;
//     let j = this.y + dy;

//     while (i !== this.kingPosX || j !== this.kingPosY) {
//       let pos = `${i}:${j}`;
//       if (this.pieces[pos]) return false;
//       i += dx;
//       j += dy;
//     }

//     return this.isDifferentColor();
//   }

//   isDiagonalMoveValid() {
//     if (Math.abs(this.kingPosX - this.x) !== Math.abs(this.kingPosY - this.y)) return false;

//     let dx = this.kingPosX > this.x ? -1 : 1;
//     let dy = this.kingPosY > this.y ? -1 : 1;

//     let i = this.x + dx;
//     let j = this.y + dy;

//     while (i !== this.kingPosX || j !== this.kingPosY) {
//       let pos = `${i}:${j}`;
//       if (this.pieces[pos]) return false;
//       i += dx;
//       j += dy;
//     }

//     return this.isDifferentColor();
//   }
// }

